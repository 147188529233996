.table-wrapper {
  overflow-y: auto;
}
.table-wrapper table {
  width: 100%;
  min-width: 400px;
  border-spacing: 0;
  font-size: 14px;
}
.table-wrapper thead {
  background-color: var(--second-bg);
}
.table-wrapper tr {
  text-align: left;
}
.table-wrapper th,
.table-wrapper td {
  text-transform: capitalize;
  padding: 7px 9px;
}
.table-wrapper tbody > tr:hover {
  background-color: var(--main-color);
  color: var(--txt-white);
}

.table__pagination {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.table__pagination-item ~ .table__pagination-item {
  margin-left: 10px;
}

.table__pagination-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.table__pagination-item.active,
.table__pagination-item.active:hover {
  background-color: var(--main-color);
  color: var(--txt-white);
  font-weight: 600;
}

.table__pagination-item:hover {
  color: var(--txt-white);
  background-color: var(--second-color);
}