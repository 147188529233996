.container {
  width: 900px;
  background: #fff;
  margin: 0 auto;
  box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -o-border-radius: 20px;
  -ms-border-radius: 20px;
}
.container .login-content {
  padding-top: 67px;
  padding-bottom: 87px;
  display: flex;
}
.container .login-content .login-image {
  margin-left: 110px;
  margin-right: 20px;
  margin-top: 10px;
}
.container .login-content .login-image figure {
  margin-bottom: 50px;
  text-align: center;
}
.container .login-content .inputError {
  border: dashed #ffcf6d 1px !important;
}
.container .login-content .inputSuccess {
  border: dashed #0ec97b 1px !important;
}
.container .login-content .signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container .login-content .signup-form .Cover {
  width: 96%;
  height: 70px;
  background-size: 100%;
  background-position-y: 364px;
  opacity: 0.666;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.container .login-content .signup-form .Cover .media1 {
  width: 75%;
}
.container .login-content .signup-form .Cover .media1 input[type=file] {
  background: transparent;
  opacity: 0;
  height: 60px;
  width: 95%;
  cursor: pointer;
}
.container .login-content .signup-form .Cover .media2 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px #f7bb51 dashed;
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
}
.container .login-content .signup-form .Cover .media2 input[type=file] {
  background: transparent;
  opacity: 0;
  height: 40px;
  cursor: pointer;
  width: 83%;
}
.container .login-content .signup-form .form-submit {
  display: inline-block;
  background: #6dabe4;
  color: #fff;
  border-bottom: none;
  width: 70%;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  margin-top: 25px;
  cursor: pointer;
  border: none;
  outline: none;
}
.container .login-content .signup-form .btn2 {
  background: #6abb7d !important;
}
.container .login-content .signup-form h2 {
  margin: 0;
  font-weight: bold;
  color: #21c3d5;
  font-family: revert;
  font-size: 25px;
  padding: 10px;
}
.container .login-content .signup-form input {
  border: 1px solid #e1f5fe;
  outline: 0;
  height: 29px;
  margin: 5px;
  border-radius: 6px;
  padding-left: 10px;
}
.container .login-content .signup-form .Row-x {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}
.container .login-content .signup-form .Row-x label {
  border: #c4e9ed 1px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
  border-radius: 15px;
  height: 30px;
  /* width: 50%; */
  margin-right: 10px;
}
.container .login-content .signup-form .register-form {
  width: 100%;
}
.container .login-content .signup-form .register-form .row {
  margin-top: 30px;
  width: 98%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}
.container .login-content .signup-form .register-form .row .form-submit:hover {
  background: #4292dc;
}