#Content_Orders {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0 0 8px 8px;
  height: 400px;
  margin-left: -105px;
  margin-top: 10px;
  padding: 0 6px;
  position: absolute;
  width: 300px;
  z-index: 100;
}
#Content_Orders .nav_order {
  margin: 4px;
}
#Content_Orders .nav_order .headList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#Content_Orders .nav_order .headList h2 {
  color: #505e78;
  float: left;
  font-size: 16px;
  margin: 3px;
}
#Content_Orders .nav_order .headList .SyncOrderList {
  float: right;
  margin: 5px;
  margin-right: 20px;
  cursor: pointer;
}
#Content_Orders .nav_order .Searches .ClientSearches {
  border-radius: 8px;
  /* border: #fafafa 1px solid; */
  color: #74a9a4;
  height: 30px;
  font-size: small;
  text-align: center;
  direction: rtl;
  width: 95%;
  background: no-repeat;
  background-position-x: right;
  background-position-y: 8px;
  border: 1px solid #eeeeeed9;
}
#Content_Orders .OrderList {
  height: 289px;
  overflow-x: hidden;
  overflow-y: scroll;
}
#Content_Orders .OrderList .TRansition {
  transition: all 0.6s ease-in-out;
}
#Content_Orders .OrderList .loading {
  padding-top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
#Content_Orders .OrderList .Order {
  color: #313333;
  height: 70px;
  cursor: pointer;
  border-bottom: 1px solid rgba(218, 224, 224, 0.2);
  font-size: 15px;
  display: flex;
  height: 70px;
  overflow: hidden;
  padding-top: 2px;
  justify-content: space-evenly;
  align-items: center;
}
#Content_Orders .OrderList .Order .ClearOrder {
  margin: 5px;
  font-size: 14px;
  color: transparent;
}
#Content_Orders .OrderList .Order .Notfi {
  height: 25px;
  width: 28px;
  border: 1px #f9f5f7 dashed;
  border-radius: 50%;
  text-align: center;
  background: #4eb4e2;
  color: #ffffff;
  font-size: 12px;
  padding: 4px;
  margin: 0 5px;
}
#Content_Orders .OrderList .Order .info {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#Content_Orders .OrderList .Order .info .userImg {
  margin: 6px;
  background-image: url("../../../images/DefaultCover.png");
  height: 35px;
  width: 35px;
  background-color: #cad3d863;
  float: left;
  background-size: cover;
  opacity: 0.7;
  border-radius: 50%;
}
#Content_Orders .OrderList .Order .info .Clint-container {
  direction: rtl;
  margin-left: 10px;
}
#Content_Orders .OrderList .Order .info .Clint-container .name {
  height: 30px;
  overflow: hidden;
  text-align: left;
  direction: rtl;
}
#Content_Orders .OrderList .Order .info .Clint-container .tag {
  color: #6cd1f3;
  float: right;
  margin: 3px;
}
#Content_Orders .OrderList .Order .info .Clint-container .orderPay {
  direction: ltr;
}
#Content_Orders .OrderList .Order .info .Clint-container .orderPay .totalOrder strong {
  color: #19bbd0;
}
#Content_Orders .OrderList .Order .info .Clint-container .orderPay .total_pcs strong {
  color: #c116e0;
  font-size: small;
}
#Content_Orders .OrderList .Selected {
  border-bottom: 2px dashed #b5e0ea;
  background-color: #ffeb6dab;
  box-shadow: -5px 5px 3px 2px #f5f4f4;
  border-left: 3px solid #00add8;
}
#Content_Orders .OrderList .Order:hover {
  border-bottom: 2px dashed #b5e0ea;
  background-color: #ffeb6dab;
  box-shadow: -5px 5px 3px 2px #f5f4f4;
  border-left: 3px solid #00add8;
  color: #dd3c16;
  font-size: medium;
}
#Content_Orders .OrderList .Order:hover .userImg {
  margin: 5px;
  height: 45px;
  width: 45px;
}
#Content_Orders .OrderList .Order:hover .ClearOrder {
  color: #209994;
}