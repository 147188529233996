@charset "UTF-8";
.wrapper-container {
  background: #fff;
  margin: 25px !important;
  min-height: 100vh;
}
.wrapper-container .Stings_nav_ {
  padding: 25px 0px 5px 25px;
}
.wrapper-container .Stings_nav_ .row {
  margin: 0px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
.wrapper-container .Stings_nav_ .row .TRansition {
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.wrapper-container .Stings_nav_ .row .p-title {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Tajawal";
}
.wrapper-container .Stings_nav_ .row hr .style16 {
  border: none;
  border-top: 1px dashed rgba(160, 210, 252, 0.38);
  border-bottom: none;
  margin-top: 20px;
}
.wrapper-container .Stings_nav_ .row hr .style16:after {
  content: "✂";
  display: inline-block;
  position: relative;
  top: -15px;
  left: 47px;
  padding: 0 3px;
  /*  background: #fafafa;*/
  color: #03a9f4;
  font-size: 18px;
}
.wrapper-container .MainStingsComponent {
  padding: 25px 0px 5px 25px;
  display: flex;
  justify-content: flex-start;
}
.wrapper-container .MainStingsComponent hr .style16 {
  border: none;
  border-top: 1px dashed rgba(160, 210, 252, 0.38);
  border-bottom: none;
  margin-top: 20px;
}
.wrapper-container .MainStingsComponent hr .style16:after {
  content: "✂";
  display: inline-block;
  position: relative;
  top: -15px;
  left: 47px;
  padding: 0 3px;
  /*  background: #fafafa;*/
  color: #03a9f4;
  font-size: 18px;
}
.wrapper-container .MainStingsComponent .leftBar {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-container .MainStingsComponent .MainStings {
  width: 75%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .inputDesc {
  font-size: 15px;
  font-weight: bold;
  text-align: right;
  line-height: 1.3;
  color: #576061;
  padding: 10px;
  direction: rtl;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .inputDesc h2 {
  font-family: "Tajawal";
  color: #59d38b;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .inputDesc span {
  font-family: "Tajawal";
  font-size: 14px;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .containerMessage {
  display: flex;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .containerMessage .imc {
  height: 380px !important;
  max-height: 380px !important;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .containerMessage .start_message_Cover {
  background-image: url("./../../../assets/images/no-product.png");
  height: 190px;
  width: 100%;
  opacity: 0.8;
  flex-direction: row-reverse;
  border: solid 0.5px #eee;
  border-radius: 7px;
  background-size: cover;
  margin-top: 10px;
  background-repeat: repeat-y;
  display: flex;
  flex-direction: row-reverse;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .containerMessage .start_message_Cover input[type=file] {
  left: 0;
  top: 0;
  opacity: 0;
  height: 150px;
  width: inherit;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .containerMessage .start_message_Cover:hover {
  opacity: 1;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .eBold {
  font-weight: bold;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .SE-op {
  border-bottom: 1px dashed #e0e2e49d;
  height: 25px;
  font-family: "Tajawal";
  font-size: 14px;
  direction: rtl;
  display: flex;
  padding: 5px;
  margin: 5px;
  align-items: center;
  color: #292a2b;
  cursor: pointer;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .SE-op .checkAction {
  color: transparent;
  padding: 5px;
  border-radius: 3px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  font-size: 20px;
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #969a9bc4 dashed;
  margin-right: 10px;
  background-size: 68%;
  background-repeat: no-repeat;
  background-position: center;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .SE-op .checkAction:hover {
  color: #ffb22b;
  border: 1px #30befbe8 dashed;
  background-image: url("./../../../assets/images/check.png");
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .SE-op .Checked-true {
  color: #ffb22b;
  border: 1px #30befbe8 dashed;
  background-image: url("./../../../assets/images/check.png");
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .SE-op:hover {
  border-bottom: 1px dashed #64aaf09d;
  color: #368eb1;
  font-weight: bold;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .SE-op:hover .checkAction {
  color: #ffb22b;
  border: 1px #30befbe8 dashed;
  background-image: url("./../../../assets/images/check.png");
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting textarea {
  border: 1px solid #cee9fbd4;
  border-radius: 6px;
  margin: 10px;
  outline: 0;
  padding: 10px;
  width: 98%;
  resize: vertical;
  direction: rtl;
  color: black;
  font-size: 12px;
  line-height: 1.5;
  overflow: hidden;
  min-height: 120px;
  max-height: 150px;
  font-family: "Tajawal";
  text-shadow: -4px 2px 6px #948c8c;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .StingAction {
  display: flex;
  justify-content: center;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .StingAction .btn-success {
  margin: 5px;
  color: #fffbfb;
  font-size: 21px;
  width: 25%;
  border-radius: 4px;
  background: #03a9f4b5;
  border: 1px solid #90caf9;
  box-shadow: 0 2px 2px 0 #e3f2fd, 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 #b3e5fc;
  transition: 0.2s ease-in;
  cursor: pointer;
  height: -moz-fit-content;
  font-family: "Tajawal";
  padding: 6px;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .StingAction .btn-active {
  background: #59d38b;
  border: 1px solid #f1f8e9;
  box-shadow: 0 2px 2px 0 #f1f8e9, 0 3px 1px -2px #dcedc8, 0 1px 5px 0 #d8f7b5b0;
  border-radius: 8px;
}
.wrapper-container .MainStingsComponent .MainStings .Form .Sting .StingAction .btn-success:hover {
  background: #59d38b;
  border: 1px solid #f1f8e9;
  box-shadow: 0 2px 2px 0 #f1f8e9, 0 3px 1px -2px #dcedc8, 0 1px 5px 0 #d8f7b5b0;
  border-radius: 8px;
}