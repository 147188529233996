:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --main-color: #349eff;
  --second-color: #62b4ff;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;
  --main-color-blue: #349eff;
  --second-color-blue: #62b4ff;
  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;
  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;
  --main-color-green: #019707;
  --second-color-green: #4caf50;
  --main-color-orange: #d68102;
  --second-color-orange: #fca11a;
  --sidebar-width: 300px;
  --border-radius: 15px;
  --topnav-height: 110px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-tap-highlight-color: transparent;
}

#transparent-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(243, 242, 242, 0);
  background-color: rgba(255, 255, 255, 0);
  border-radius: 0%;
}

#transparent-scroll::-webkit-scrollbar {
  width: 0px;
  background-color: rgba(187, 186, 186, 0);
}

#transparent-scroll::-webkit-scrollbar-thumb {
  background-color: #ffffff00;
  border-radius: 0px;
  background-image: -webkit-linear-gradient(50deg, rgba(255, 255, 255, 0) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0) 50%, rgba(250, 250, 250, 0) 75%, transparent 85%, transparent);
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
  color: #67757c;
}
body a {
  text-decoration: none;
  color: unset;
}
body a:hover {
  color: var(--main-color);
}
body button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: var(--txt-color);
}
body ul {
  list-style-type: none;
}
body input {
  border: 2px solid transparent;
  outline: 0;
}
body input:focus {
  border: 2px solid var(--main-color);
}
body .page-header {
  margin-bottom: 40px;
  text-transform: capitalize;
}
body .card {
  border: 1px solid #eee;
  padding: 30px;
  margin-bottom: 30px;
  background-color: var(--main-bg);
  /* box-shadow: var(--box-shadow); */
  border-radius: var(--border-radius);
}
body .full-height {
  height: 100%;
}
body .card.full-height {
  height: calc(100% - 30px);
}
body .card__header {
  text-transform: capitalize;
}
body .card > div ~ div {
  margin-top: 30px;
}
body .card__footer {
  text-align: center;
  text-transform: capitalize;
}
body .light-background {
  background-color: var(--main-bg-light);
  color: #000;
}
body .dark-background {
  background-color: var(--main-bg-dark);
  color: #fff;
}
body .blue-color {
  background-color: var(--main-color-blue);
  color: #fff;
}
body .red-color {
  background-color: var(--main-color-red);
  color: #fff;
}
body .cyan-color {
  background-color: var(--main-color-cyan);
  color: #fff;
}
body .green-color {
  background-color: var(--main-color-green);
  color: #fff;
}
body .orange-color {
  background-color: var(--main-color-orange);
  color: #fff;
}
body .ReactModal__Overlay {
  z-index: 99;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
}
body .ReactModal__Content {
  position: absolute;
  left: auto;
  right: auto;
  top: 2.5rem;
  bottom: 2.5rem;
  background-color: #fff;
  box-shadow: -6px 6px 14px 14px #e6e4eb;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  width: 50%;
  margin: auto;
  border: #eee;
  height: 500px;
  border: 1px solid #ecf0f3;
}
body .CloseModal {
  font-size: 23px;
  margin: -17px -10px 0px 0px;
  float: right;
  color: #aeafb1;
  cursor: pointer;
}
body .Layout {
  display: grid;
  grid-template-areas: "header" "main" "footer";
  min-height: 100vh;
  grid-template-rows: 45px 1fr 40px;
  background-color: rgba(245, 247, 249, 0.87);
}
body main {
  grid-area: main;
  margin-left: 45px;
}
body .wrapper {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px 20px;
}
body .wrapper-right-side {
  width: 24%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 7px;
  max-height: 870px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  right: 15px;
  width: 23%;
}
body hr.style16 {
  border: none;
  border-top: 1px dashed rgba(160, 210, 252, 0.38);
}
body .Transition {
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
body .css-eglki6-MuiLinearProgress-root {
  position: relative;
  overflow: hidden;
  display: block;
  height: 2px;
  z-index: 0;
  background-color: #eaf2f7;
}
body .sidebar {
  width: 50px;
  position: absolute;
  left: 0px;
  overflow: hidden;
}
body .left-sidebar {
  background-color: #fbfbfb;
  min-height: 350px;
  border-right: solid 1px #eee;
  width: 95%;
  margin-top: 100px;
  border-radius: 0px 15px 15px 0px;
}
body .css-1mxz8qt-MuiPaper-root {
  box-shadow: unset;
}
body .IsLoading {
  width: 100%;
  height: 90px;
  background-repeat: no-repeat;
  background-position: center;
}
body .Notfound {
  width: 100%;
  min-height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}
body .IsLoading {
  background-image: url("../images/preloa.gif");
}
body .Notfound {
  background-image: url("../images/no-product.png");
}