.wrapper-product {
  display: flex;
  margin: 25px;
  justify-content: center;
  align-items: flex-start;
}
.wrapper-product .Box {
  background: #ffffff none repeat scroll 0 0;
  margin: 15px 0;
  padding: 20px;
  border: 0 solid #e7e7e7;
  border-radius: 5px;
  margin: 5px;
}
.wrapper-product .WallPosts {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.wrapper-product .WallPosts .conte-center {
  width: fit-content;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  margin: 5px 0px;
  margin-bottom: 0px;
  border-bottom: 1px dashed #9ccce180;
}
.wrapper-product .WallPosts .conte-center .hide {
  display: none;
}
.wrapper-product .WallPosts .conte-center .CustomForm {
  width: 100% !important;
}
.wrapper-product .WallPosts .conte-center .ProductForm {
  width: 50%;
  text-align: right;
  direction: rtl;
}
.wrapper-product .WallPosts .conte-center .ProductForm .product_description textarea {
  border: 1px solid rgba(238, 238, 238, 0.7215686275);
  border-radius: 8px;
  font-size: 15px;
  width: 100%;
  padding: 10px;
  outline: none;
  color: rgba(100, 95, 106, 0.92);
  text-align: right;
  direction: rtl;
  margin: 5px;
  font-family: Tajawal;
  max-width: 425px;
  resize: none;
  height: 85px;
  overflow: hidden;
}
.wrapper-product .WallPosts .conte-center .ProductForm .productSize {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}
.wrapper-product .WallPosts .conte-center .ProductForm .productSize .S_NUM {
  cursor: pointer;
  padding: 3px;
  border: 1px dashed #b1d5c6;
  border-radius: 4px;
  color: cadetblue;
  margin: 3px;
}
.wrapper-product .WallPosts .conte-center .ProductForm .productSize .S_NUM:hover {
  background: #d4f3db;
  color: #046372b3;
  border: 1px solid #a9d9b4cf;
}
.wrapper-product .WallPosts .conte-center .ProductForm .productSize .Size-checked {
  background: #d4f3db;
  color: #046372b3;
  border: 1px solid #a9d9b4cf;
}
.wrapper-product .WallPosts .conte-center .ProductForm .ProductPrice {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.wrapper-product .WallPosts .conte-center .ProductForm .ProductPrice input[type=text] {
  width: 25%;
  text-align: center;
  color: #777a7d;
  font-size: medium;
  direction: ltr;
  padding-top: 10px;
}
.wrapper-product .WallPosts .conte-center .ProductForm input[type=text] {
  border: 1px solid rgba(238, 238, 238, 0.7215686275);
  border-radius: 8px;
  font-size: 33px;
  padding: 5px;
  outline: none;
  color: rgba(100, 95, 106, 0.92);
  text-align: right;
  direction: rtl;
  text-shadow: -4px 2px 6px #948c8c;
  margin: 5px;
  font-family: Tajawal;
}
.wrapper-product .WallPosts .conte-center .ProductForm .productName {
  width: 100%;
}
.wrapper-product .WallPosts .conte-center .ProductForm .Hashtag {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
}
.wrapper-product .WallPosts .conte-center .ProductForm .Hashtag .times-remov {
  margin-left: 10px;
  color: #b7b4b4;
}
.wrapper-product .WallPosts .conte-center .ProductForm .Hashtag .times-remov:hover {
  margin-left: 10px;
  color: red;
}
.wrapper-product .WallPosts .conte-center .ProductForm .Hashtag .Hash {
  color: #464a40;
  font-family: Harmattan;
  line-height: 0.6;
  margin: 5px;
}
.wrapper-product .WallPosts .conte-center .ProductForm .Hashtag .Hash:hover {
  color: #673ab7;
  text-decoration: underline;
}
.wrapper-product .WallPosts .conte-center .ProductForm .Hashtag .custom-Hash {
  color: blueviolet;
  font-size: x-large;
}
.wrapper-product .WallPosts .conte-center .upload-btn-wrapper {
  margin: 8px;
  width: 395px;
  height: 350px;
  cursor: pointer;
  border-radius: 5px 5px 25px 25px;
  background-size: 100%;
  opacity: 0.8;
  background-color: rgba(165, 216, 232, 0.0901960784);
  border: 1px dashed #dbe0e1;
  background-repeat: no-repeat;
}
.wrapper-product .WallPosts .conte-center .upload-btn-wrapper input[type=file] {
  left: 0;
  top: 0;
  opacity: 0;
  height: inherit;
  width: inherit;
}
.wrapper-product .WallPosts .conte-center .upload-btn-wrapper:hover {
  opacity: 0.9;
}
.wrapper-product .WallPosts .custom-container {
  height: 87px;
  overflow: hidden;
  width: 100% !important;
}
.wrapper-product .WallPosts .formAction {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-product .WallPosts .formAction .savform {
  margin: 5px;
  border: 1px dashed #35aaf3;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-size: 59%;
  background-repeat: no-repeat;
  background-position: center;
}
.wrapper-product .WallPosts .formAction .Checked-true {
  background-image: url("./../../../assets/images/check.png");
}
.wrapper-product .WallPosts .formAction .savform:hover {
  background-image: url("./../../../assets/images/check.png");
}
.wrapper-product .WallPosts .formAction .btn-success {
  margin: 5px;
  color: #fffbfb;
  font-size: 21px;
  width: 50%;
  border-radius: 4px;
  background: #03a9f4b5;
  border: 1px solid #90caf9;
  box-shadow: 0 2px 2px 0 #e3f2fd, 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 #b3e5fc;
  transition: 0.2s ease-in;
  font-family: monospace;
  padding: 13px;
  cursor: pointer;
}
.wrapper-product .WallPosts .formAction .btn-success:hover {
  background: #59d38b;
  border: 1px solid #f1f8e9;
  box-shadow: 0 2px 2px 0 #f1f8e9, 0 3px 1px -2px #dcedc8, 0 1px 5px 0 #d8f7b5b0;
  border-radius: 15px;
}
.wrapper-product .conte-left-side {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
}
.wrapper-product .conte-left-side .cat {
  border: 1px dashed #8ae5f1;
  height: 29px;
  border-radius: 8px;
  background: rgba(195, 252, 251, 0.2);
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  margin: 5px;
  color: cadetblue;
  cursor: pointer;
}
.wrapper-product .conte-left-side .cat:hover {
  background: rgba(40, 190, 189, 0.42);
  color: #086252;
}
.wrapper-product .conte-left-side .IsSelected {
  background: rgba(40, 190, 189, 0.42);
  color: #2e3425;
}
.wrapper-product .conte-right-side {
  width: 20%;
}
@media screen and (max-width: 780px) {
  .wrapper-product .conte-left-side,
.wrapper-product .conte-right-side {
    display: none;
  }
  .wrapper-product .WallPosts {
    width: 100%;
  }
  .wrapper-product .WallPosts .conte-center {
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }
  .wrapper-product .WallPosts .conte-center .upload-btn-wrapper {
    width: 98%;
  }
  .wrapper-product .WallPosts .conte-center .ProductForm {
    width: 100%;
  }
}
@media screen and (max-width: 870px) {
  .wrapper-product .conte-left-side,
.wrapper-product .conte-right-side {
    display: none;
  }
  .wrapper-product .WallPosts {
    width: 100%;
  }
  .wrapper-product .WallPosts .conte-center {
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }
  .wrapper-product .WallPosts .conte-center .upload-btn-wrapper {
    width: 98%;
  }
  .wrapper-product .WallPosts .conte-center .ProductForm {
    width: 100%;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .wrapper-product .conte-left-side,
.wrapper-product .conte-right-side {
    display: none;
  }
  .wrapper-product .WallPosts {
    width: 100%;
  }
  .wrapper-product .WallPosts .conte-center {
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }
  .wrapper-product .WallPosts .conte-center .upload-btn-wrapper {
    width: 98%;
  }
  .wrapper-product .WallPosts .conte-center .ProductForm {
    width: 100%;
  }
  .wrapper-product .WallPosts .container-post {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .wrapper-product .WallPosts .container-post .ActionPost {
    width: 90%;
    margin: 0px 5px 0px 10px;
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
    align-items: center;
  }
  .wrapper-product .WallPosts .container-post .ActionPost .user_ {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }
  .wrapper-product .WallPosts .container-post .content {
    width: 90%;
  }
}