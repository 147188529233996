.ItemCampaign {
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
}
.ItemCampaign .OBjCmp {
  height: 35px;
  display: flex;
  color: #080707f0;
  background-color: #8ee2fd;
  border-radius: 4px;
  justify-content: center;
  text-shadow: -4px 2px 6px #948c8c;
  font-family: "Tajawal" !important;
  align-items: center;
}
.ItemCampaign .OBjCmp svg {
  margin-left: 10px;
}
.ItemCampaign .listOBJ {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ItemCampaign .listOBJ .HeadComp {
  width: 95%;
  direction: rtl;
  padding: 6px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px dashed #f7f2f2;
  align-items: center;
  color: cadetblue;
}
.ItemCampaign .listOBJ .HeadComp .TitleComp {
  width: 60%;
}
.ItemCampaign .listOBJ .HeadComp .op {
  display: flex;
  width: 45%;
  justify-content: space-between;
  align-items: center;
}
.ItemCampaign .listOBJ .formObject {
  width: 98%;
  background: rgba(176, 191, 191, 0.12);
  direction: rtl;
  padding: 6px;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px dashed #f7f2f2;
  align-items: center;
}
.ItemCampaign .listOBJ .formObject .list {
  width: fit-content;
  height: 30px;
  border: solid 1px #eee;
  background: transparent;
  border-radius: 4px;
  outline: none;
}
.ItemCampaign .listOBJ .formObject .btnAdd {
  width: 25px;
  height: 25px;
  border: #c1c6c9 1px dashed;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: darkgray;
  cursor: pointer;
}
.ItemCampaign .listOBJ .formObject .btnAdd:hover {
  border: #048ee4 1px dashed;
  color: #048ee4;
}
.ItemCampaign .listOBJ .item-obj {
  width: 95%;
  direction: rtl;
  padding: 3px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #f7f2f2;
  align-items: center;
  flex-direction: row;
}
.ItemCampaign .listOBJ .item-obj .check {
  height: 20px;
  width: 20px;
  background-size: 12px !important;
  border: #b5b8b9 1px dashed;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border-radius: 3px;
}
.ItemCampaign .listOBJ .item-obj .check:hover {
  border: #048ee4 1px dashed;
  background: url("../../../images/check.png");
  transition: all 0.6s ease-in-out;
}
.ItemCampaign .listOBJ .item-obj .checked {
  border: #048ee4 1px dashed;
  background: url("../../../images/check.png");
  transition: all 0.6s ease-in-out;
}
.ItemCampaign .listOBJ .item-obj .title {
  text-shadow: -4px 2px 6px #948c8c;
  font-family: "tahoma";
  color: #3a3a3c;
  font-size: 14px;
}
.ItemCampaign .listOBJ .item-obj .op {
  display: flex;
  width: 45%;
  justify-content: space-between;
  align-items: center;
}
.ItemCampaign .listOBJ .item-obj .op span {
  color: #3e403ed6;
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  font-size: smaller;
}
.ItemCampaign .listOBJ .item-obj .op .showPrice {
  height: 20px;
  width: 45px;
  background-position: center !important;
  background-size: 85% !important;
  background-repeat: no-repeat !important;
}
.ItemCampaign .listOBJ .item-obj .op .active {
  background: url("../../../images/SW-ON.png");
}
.ItemCampaign .listOBJ .item-obj .op .UnActive {
  background: url("../../../images/SW-OFF.png");
}
.ItemCampaign .titleCmp {
  height: 35px;
  display: flex !important;
  padding-right: 40px;
  color: #fefefe !important;
  background-color: #00c0ff;
  border-radius: 4px;
  direction: rtl;
  justify-content: flex-end !important;
  flex-direction: row-reverse !important;
  font-size: smaller !important;
}
.ItemCampaign .style16 {
  width: 100%;
  border: none;
  border-bottom: 1px dashed rgba(160, 210, 252, 0.38);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 5px;
  flex-direction: row-reverse;
  color: cadetblue;
}
.ItemCampaign .style16 .GoBack {
  cursor: pointer;
}
.ItemCampaign .style16 .GoBack:hover {
  color: #f00d99;
  cursor: pointer;
}
.ItemCampaign .style16 h2 {
  font-size: 20px;
  margin: 5px;
  text-align: right;
}
.ItemCampaign .options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.ItemCampaign .options-container .Button-custom {
  border: 1px dashed #eeeeee;
  border-radius: 10px;
  cursor: pointer;
  margin: auto;
  text-align: center;
  color: #1dc9e0;
  margin: 3px;
  padding: 5px 7px;
}
.ItemCampaign .options-container .Button-custom:hover {
  border: 1px dashed #048ee4;
}
.ItemCampaign .options-container .Check-inOrder {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-size: 68%;
  background-repeat: no-repeat;
  background-position: center;
}
.ItemCampaign .options-container .Checked-true {
  background-image: url("./../../../../assets/images/check.png");
}
.ItemCampaign .options-container .Check-inOrder:hover {
  background-image: url("./../../../../assets/images/check.png");
}
.ItemCampaign .listItem {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 620px;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 5px;
}
.ItemCampaign .listItem .item {
  border-bottom: 1px dashed #d8dde5ab;
  height: 56px;
  width: 98%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 3px;
}
.ItemCampaign .listItem .item .item-description {
  width: 85%;
}
.ItemCampaign .listItem .item .item-description .title {
  margin: 8px;
  color: #6a726f;
  font-size: 14px;
}
.ItemCampaign .listItem .item .item-description div {
  font-size: smaller;
  margin: 5px;
}
.ItemCampaign .listItem .item .item-description div span {
  font-size: 12px;
}
.ItemCampaign .listItem .item .item-description div .span-price {
  color: #2d9714;
}
.ItemCampaign .listItem .item .item-description div .span-Q {
  color: #db2f85;
}
.ItemCampaign .listItem .item .productImg {
  height: 40px;
  width: 50px;
  background-position: center;
  background-size: 98%;
  background-repeat: no-repeat;
  border-radius: 8px;
  margin: 5px 5px 0px 5px;
}
.ItemCampaign .listItem .item .clearItem,
.ItemCampaign .listItem .item .shearItem {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  color: #ffffff;
  justify-content: flex-end;
  font-size: small;
  cursor: pointer;
}
.ItemCampaign .listItem .selected {
  background-color: #d4f3db;
}
.ItemCampaign .listItem .selected .shearItem {
  color: #ffffff;
}
.ItemCampaign .listItem .Published {
  border-left: 3px solid #77c389;
  border-radius: 0px 5px 5px 0px !important;
}
.ItemCampaign .listItem .item:hover {
  background-color: #ebeced;
}
.ItemCampaign .listItem .item:hover .clearItem {
  color: #db2f85;
}
.ItemCampaign .listItem .item:hover .shearItem {
  color: #ffffff;
}
.ItemCampaign .listItem .item:hover .shearItem:hover {
  color: #57a8e0;
}
.ItemCampaign button {
  width: 50%;
  background: #26dad2;
  border: 1px solid #26dad2;
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  transition: 0.2s ease-in;
  margin: 3px;
  border-radius: 5px;
  text-align: center;
  color: #fafafa;
  padding: 10px;
}
.ItemCampaign .btn-success:hover {
  opacity: 0.7;
  border: 1px solid #fb3a3a;
  background: #fb3a3a;
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}