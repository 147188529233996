@charset "UTF-8";
.main-home {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 98%;
  height: 100vh;
}
.main-home .analysis {
  display: flex;
  width: 98%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.main-home .analysis .card {
  padding: 30px;
  margin-bottom: 30px;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.main-home .analysis .card .full-height {
  height: calc(100% - 30px);
}
.main-home .analysis .card > div ~ div {
  margin-top: 30px;
}
.main-home .analysis .card .full-height {
  height: 100%;
}
.main-home .analysis .card .card__header {
  text-transform: capitalize;
}
.main-home .analysis .card .card__footer {
  text-align: center;
  text-transform: capitalize;
}
.main-home .analysis .charts {
  width: 40%;
}
.main-home .analysis .cards {
  width: 40%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
.main-home .orders {
  display: flex;
  justify-content: space-between;
}
.main-home .orders hr {
  border: 1px solid #e7e7e7ab;
  margin: 5px 0px 10px 0px;
}
.main-home .orders h3 {
  color: currentcolor;
  text-align: center;
  font-family: monospace;
}
.main-home .orders .lastOrders {
  width: 60%;
}
.main-home .orders .MainTopClint {
  width: 38%;
}
.main-home .orders .MainTopClint .TopClint {
  display: flex;
  height: 30px;
  margin: 2px;
  flex-direction: row-reverse;
  align-items: center;
  border-bottom: 1px solid #f1f4f5;
  justify-content: flex-start;
}
.main-home .orders .MainTopClint .TopClint span {
  margin-left: 10px;
}
.main-home .orders .MainTopClint .TopClint .City {
  color: #5aaff7;
}
.main-home .orders .MainTopClint .TopClint .City::after {
  font-size: 11px;
  content: " ⭐️ ";
}
.main-home .orders .MainTopClint .TopClint .QV {
  background-color: #5aaff7;
  margin: 5px;
  padding: 2px 5px;
  color: #fff;
  font-style: revert;
  border-radius: 3px;
  font-family: cursive;
}
.main-home .orders .MainTopClint .TopClint .userImg {
  margin: 6px;
  background-image: url("../../images/DefaultCover.png");
  height: 22px;
  width: 22px;
  background-color: #cad3d863;
  float: left;
  background-size: cover;
  opacity: 0.7;
  border-radius: 50%;
}
.main-home .orders .MainTopClint .TopClint:hover {
  border-bottom: 1px dashed #c1d3e3;
}
.main-home .orders .MainTopClint h1 {
  font-size: xxx-large;
  color: #0cbb8b;
  text-align: center;
  font-family: cursive;
}
.main-home .orders .MainTopClint h3 {
  color: currentcolor;
  text-align: center;
  font-family: monospace;
}
.main-home .orders .MainTopClint .mQ {
  font-size: xx-large;
  color: #a0a7a1;
  text-decoration: line-through #77a79a dashed;
}
.main-home .orders .MainTopClint .wallet {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.main-home .orders .MainTopClint .wallet div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28%;
  height: 30px;
  color: darkcyan;
}
.main-home .orders .MainTopClint .wallet div img {
  height: 25px;
  margin: 5px;
}
.main-home .orders .MainTopClint .wallet div span {
  font-size: 12px;
}
@media screen and (max-width: 780px) {
  .main-home .orders {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .main-home .orders .lastOrders {
    width: 101%;
    margin: 20px 0px 20px 0px;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 870px) {
  .main-home .orders {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
  }
  .main-home .orders .lastOrders {
    width: 101%;
    margin: 20px 0px 20px 0px;
  }
}

@media print {
  .Layout {
    background-color: white;
  }
}
@media screen and (max-device-width: 430px) {
  main .main-home {
    display: flex;
    flex-direction: column;
    /* width: 98%; */
    height: 100vh;
    align-content: center;
    padding-left: 40px;
    padding-top: 250px;
  }
  main .main-home .orders .MainTopClint {
    width: 100%;
    margin: 0px 0px 50px 5px;
  }
  main .main-home .orders .lastOrders {
    display: none;
  }
  main .main-home .container_last_products {
    display: flex;
    justify-content: space-evenly !important;
  }
  main .main-home .container_last_products .Item:nth-last-child(-n+6) {
    display: none;
  }
  main .main-home .analysis {
    display: flex;
    width: 98%;
    margin-top: 50px;
    flex-direction: column-reverse;
    align-items: center;
    align-content: stretch;
    /* padding-left: 40px;*/
  }
  main .main-home .analysis .charts {
    width: 100%;
    position: fixed;
    z-index: 101;
    top: 45px;
    margin: 0px;
    left: 0px;
  }
  main .main-home .analysis .charts .card {
    padding: 24px !important;
    border-radius: 0px 0px 20px 20px !important;
  }
  main .main-home .analysis .cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  main .main-home .analysis .cards .status-card {
    padding: 30px;
    display: flex;
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 0.5s ease 0s;
    margin-bottom: 30px;
    width: 85%;
  }
}