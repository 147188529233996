.EditProduct {
  display: flex;
  flex-direction: column;
}
.EditProduct .Suppliers_ .round {
  border: 1px #eee solid;
  /* padding: 5px; */
  border-radius: 5px;
  width: 95%;
  margin: 5px;
  background: transparent;
  outline: none;
  padding: 5px 0px 8px 8px;
}
.EditProduct .Suppliers_ .round option {
  background: #ffb22b9c;
}
.EditProduct .Suppliers_ .round option:focus {
  background: #fffdfae0;
  color: #086252;
}
.EditProduct form {
  display: flex;
  flex-direction: column;
}
.EditProduct form .style16 {
  width: 100%;
  border: none;
  border-bottom: 1px dashed rgba(160, 210, 252, 0.38);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 5px;
}
.EditProduct form .style16 h2 {
  color: cadetblue;
  font-size: 20px;
  margin: 5px;
  text-align: right;
}
.EditProduct form .style16 .savForm {
  margin: 5px;
  border: 1px dashed #35aaf3;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-size: 59%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.EditProduct form .style16 .Checked-true {
  background-image: url("./../../../../assets/images/check.png");
}
.EditProduct form .style16 .savForm:hover {
  background-image: url("./../../../../assets/images/check.png");
}
.EditProduct form .upload-btn-wrapper {
  margin: 8px;
  height: 300px;
  cursor: pointer;
  border-radius: 5px 5px 25px 25px;
  background-size: 100%;
  opacity: 0.8;
  background-color: rgba(165, 216, 232, 0.0901960784);
  border: 1px dashed #dbe0e1;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row-reverse;
}
.EditProduct form .upload-btn-wrapper .MultipleIMG_bar {
  width: 100px;
  height: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
  align-content: center;
  margin-right: 15px;
}
.EditProduct form .upload-btn-wrapper .MultipleIMG_bar .Item {
  width: 43%;
  height: 43px;
  margin: 1px;
  background-color: #fbfefec4;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 7.5;
  border: 3px solid #ffffff;
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
}
.EditProduct form .upload-btn-wrapper .MultipleIMG_bar .Item .RemoveImg {
  font-size: 11px;
  color: transparent;
  margin-right: 3px;
}
.EditProduct form .upload-btn-wrapper .MultipleIMG_bar .Item .RemoveImg:hover {
  color: #e53b3b;
  font-size: 15px;
}
.EditProduct form .upload-btn-wrapper .MultipleIMG_bar .Item:hover {
  opacity: 9;
  border: 3px solid;
  border-color: #274978;
}
.EditProduct form .upload-btn-wrapper .MultipleIMG_bar .Item:hover .RemoveImg {
  color: #e53b3b;
}
.EditProduct form .upload-btn-wrapper .MultipleIMG_bar .checked {
  opacity: 9;
  border: 3px solid;
  border-color: #274978;
}
.EditProduct form .upload-btn-wrapper input[type=file] {
  left: 0;
  top: 0;
  opacity: 0;
  height: inherit;
  width: inherit;
}
.EditProduct form .upload-btn-wrapper:hover {
  opacity: 0.9;
}
.EditProduct form .product_description textarea {
  border: 1px solid rgba(238, 238, 238, 0.7215686275);
  border-radius: 8px;
  font-size: 15px;
  width: 98%;
  padding: 10px;
  outline: none;
  color: rgba(100, 95, 106, 0.92);
  text-align: right;
  direction: rtl;
  margin: 5px;
  font-family: Tajawal;
  max-width: 425px;
  resize: none;
  height: 85px;
  overflow: hidden;
}
.EditProduct form button {
  width: 50%;
  background: #26dad2;
  border: 1px solid #26dad2;
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  transition: 0.2s ease-in;
  margin: 3px;
  border-radius: 5px;
  text-align: center;
  color: #fafafa;
  padding: 10px;
}
.EditProduct form .btn-success:hover {
  opacity: 0.7;
  border: 1px solid #fb3a3a;
  background: #fb3a3a;
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}
.EditProduct form .productSize {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.EditProduct form .productSize .S_NUM {
  cursor: pointer;
  padding: 3px;
  border: 1px dashed #b1d5c6;
  border-radius: 4px;
  color: cadetblue;
  margin: 3px;
}
.EditProduct form .productSize .S_NUM:hover {
  background: #d4f3db;
  color: #046372b3;
  border: 1px solid #a9d9b4cf;
}
.EditProduct form .productSize .Size-checked {
  background: #d4f3db;
  color: #046372b3;
  border: 1px solid #a9d9b4cf;
}
.EditProduct form input[type=text] {
  border: 1px solid rgba(238, 238, 238, 0.7215686275);
  border-radius: 8px;
  font-size: 33px;
  padding: 5px;
  outline: none;
  color: rgba(100, 95, 106, 0.92);
  text-align: right;
  direction: rtl;
  text-shadow: -4px 2px 6px #948c8c;
  margin: 5px;
  font-family: Tajawal;
  width: 98%;
}
.EditProduct form .ProductPrice {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row-reverse;
}
.EditProduct form .ProductPrice .productCode {
  color: #673ab7 !important;
}
.EditProduct form .ProductPrice input[type=text] {
  width: 25%;
  text-align: center;
  color: #777a7d;
  font-size: medium;
  direction: ltr;
  padding-top: 10px;
}
.EditProduct form .Hashtag {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
}
.EditProduct form .Hashtag .times-remov {
  margin-left: 10px;
  color: #b7b4b4;
}
.EditProduct form .Hashtag .times-remov:hover {
  margin-left: 10px;
  color: red;
}
.EditProduct form .Hashtag .Hash {
  color: #464a40;
  font-family: Harmattan;
  line-height: 0.6;
  margin: 5px;
}
.EditProduct form .Hashtag .Hash:hover {
  color: #673ab7;
  text-decoration: underline;
}
.EditProduct form .Hashtag .custom-Hash {
  color: blueviolet;
  font-size: x-large;
}
.EditProduct form .cat-counters .conte-left-side {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 80px;
  justify-content: center;
}
.EditProduct form .cat-counters .conte-left-side .cat {
  background: rgba(195, 252, 251, 0.2);
  border: 1px dashed #8ae5f1;
  border-radius: 5px;
  color: #5f9ea0;
  cursor: pointer;
  margin: 1px;
  padding: 0px 5px;
  width: -moz-fit-content;
  /* width: -webkit-fit-content; */
  width: fit-content;
  font-size: 12px;
}
.EditProduct form .cat-counters .conte-left-side .cat:hover {
  background: rgba(40, 190, 189, 0.42);
  color: #086252;
}
.EditProduct form .cat-counters .conte-left-side .IsSelected {
  background: rgba(40, 190, 189, 0.42);
  color: #2e3425;
}