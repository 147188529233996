@charset "UTF-8";
.wrapper-container {
  background: #fff;
  margin: 25px !important;
}
.wrapper-container .row {
  margin: 0px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
.wrapper-container .row .head-btn {
  display: flex;
}
.wrapper-container .row .head-btn .tab-btn {
  border: 1px solid #aad5e9;
  background-color: #f0f8ff3b;
  margin-left: 8px;
  border-radius: 8px;
  padding: 0px 8px;
  color: #588295;
  cursor: pointer;
}
.wrapper-container .row .head-btn .tab-btn:hover {
  background-color: #f0f8ff;
  border: 1px dashed #3fa6d6;
  color: #588295;
}
.wrapper-container .TRansition {
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.wrapper-container h1 {
  color: #29b3c3;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
}
.wrapper-container .list {
  width: 95%;
  height: 35px;
  border: solid 1px #eee;
  background: transparent;
  border-radius: 8px;
  margin: 8px;
  outline: none;
}
.wrapper-container .inputSuccess {
  border: dashed #0ec97b 1px !important;
}
.wrapper-container .inputError {
  border: dashed #ffcf6d 1px !important;
}
.wrapper-container .Block_nav_ {
  padding: 25px 0px 5px 25px;
}
.wrapper-container .Block_nav_ .p-title {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-container .Block_nav_ hr .style16 {
  border: none;
  border-top: 1px dashed rgba(160, 210, 252, 0.38);
  border-bottom: none;
  margin-top: 20px;
}
.wrapper-container .Block_nav_ hr .style16:after {
  content: "✂";
  display: inline-block;
  position: relative;
  top: -15px;
  left: 47px;
  padding: 0 3px;
  /*  background: #fafafa;*/
  color: #03a9f4;
  font-size: 18px;
}
.wrapper-container .Block_nav_ .SearchCat {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-container .Block_nav_ .SearchCat input {
  border: dashed 2px #dae2e4;
  height: 50px;
  padding: 16px;
  color: #647073;
  font-size: 1.08em;
  border-radius: 19px;
  direction: rtl;
  text-align: center;
  background: url("./../../../assets/images/sh-robot.gif") no-repeat 10px;
  width: 75%;
  outline-style: none;
  background-size: 30px;
}
.wrapper-container .Block_nav_ #MainCat {
  display: flex;
  justify-content: center;
  width: 50%;
}
.wrapper-container .Block_nav_ #MainCat .cat_H:hover {
  border: dashed 3px #10c0e5;
  width: 165px;
}
.wrapper-container .Block_nav_ #MainCat .cat_H:hover h1,
.wrapper-container .Block_nav_ #MainCat .cat_H:hover span {
  display: block;
}
.wrapper-container .Block_nav_ #MainCat .cat_H {
  border: dashed 3px #dbdfe6;
  margin: 5px;
  border-radius: 15px;
  height: 85px;
  width: 85px;
  overflow: hidden;
}
.wrapper-container .Block_nav_ #MainCat .cat_H h1 {
  display: none;
  font-size: 2.2em;
  font-weight: bolder;
  margin: 15px 10px 0px 10px;
  padding-bottom: 0px;
  color: #5db3be;
  line-height: 35px;
}
.wrapper-container .Block_nav_ #MainCat .cat_H span {
  display: none;
  color: cadetblue;
}
.wrapper-container .Block_nav_ #MainCat .cat_H .Cat_img {
  height: 71px;
  width: 70px;
  float: left;
  opacity: 0.7;
  background-color: #cad3d863;
  border-radius: 5px;
  background-size: cover;
  margin: 5px 5px;
  background-repeat: no-repeat;
  background-size: cover;
}
.wrapper-container .MainChatComponent {
  display: flex;
  margin: 25px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  border-radius: 8px;
}
.wrapper-container .MainChatComponent .NewBlock {
  width: 20%;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}
.wrapper-container .MainChatComponent .NewBlock .labelblock {
  display: flex;
}
.wrapper-container .MainChatComponent .NewBlock .AutherMedia {
  overflow: hidden;
  height: 60px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper-container .MainChatComponent .NewBlock .BtnBlockType {
  width: 35px;
}
.wrapper-container .MainChatComponent .NewBlock .BtnBlockType .tab-btn {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  margin: 5px;
  border: dashed 1px #c1c4c6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: cadetblue;
}
.wrapper-container .MainChatComponent .NewBlock .BtnBlockType .tab-btn:hover {
  border: dashed 1px #10c0e5;
  color: #10c0e5;
  cursor: pointer;
}
.wrapper-container .MainChatComponent .NewBlock .BtnBlockType .active {
  border: dashed 1px #10c0e5;
  color: #10c0e5;
  cursor: pointer;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Cover {
  background-image: url("./../../../assets/images/no-product.png");
  height: 190px;
  width: 100%;
  opacity: 0.8;
  display: flex;
  flex-direction: row-reverse;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Cover input[type=file] {
  left: 0;
  top: 0;
  opacity: 0;
  height: 150px;
  width: inherit;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Cover .MultipleIMG_bar {
  width: 100px;
  height: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 5px;
  align-content: center;
  margin-right: 5px;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Cover .MultipleIMG_bar .Item {
  width: 33px;
  height: 33px;
  margin: 1px;
  background-color: #fbfefec4;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 7.5;
  border: 2px solid #ffffff;
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Cover .MultipleIMG_bar .Item .RemoveImg {
  font-size: 11px;
  color: transparent;
  margin-right: 3px;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Cover .MultipleIMG_bar .Item .RemoveImg:hover {
  color: #e53b3b;
  font-size: 15px;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Cover .MultipleIMG_bar .Item:hover {
  opacity: 9;
  border: 2px solid;
  border-color: #10c0e5;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Cover .MultipleIMG_bar .Item:hover .RemoveImg {
  color: #e53b3b;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Cover .MultipleIMG_bar .checked {
  opacity: 9;
  border: 3px solid;
  border-color: #10c0e5;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Cover:hover {
  opacity: 1;
}
.wrapper-container .MainChatComponent .NewBlock .Header_Block .mediaUpload {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: flex-start;
  background: url("./../../../assets/images/pattern.png");
  background-position-y: 13px;
}
.wrapper-container .MainChatComponent .NewBlock .Header_Block .mediaUpload input[type=file] {
  left: 0;
  top: 0;
  opacity: 0;
  height: 40px;
  width: inherit;
  cursor: pointer;
}
.wrapper-container .MainChatComponent .NewBlock .Header_Block .info {
  font-size: small;
  color: cadetblue;
  text-align: center;
  line-height: 1.7;
}
.wrapper-container .MainChatComponent .NewBlock input,
.wrapper-container .MainChatComponent .NewBlock textarea {
  border: none;
  border-radius: 5px;
  width: 95%;
  outline-style: none;
  text-align: left;
  direction: ltr;
  resize: none;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat input::placeholder {
  color: #b3c2c5;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat textarea::placeholder {
  color: #b3c2c5;
}
.wrapper-container .MainChatComponent .NewBlock .Gallery_title {
  padding: 8px;
  color: #657165;
  margin: 5px;
}
.wrapper-container .MainChatComponent .NewBlock .Gallery_subtitle {
  height: 145px;
  margin: 5px;
  overflow: hidden;
  color: #1c1d1dab;
  font-size: small;
  padding: 10px;
  line-height: 1.5;
  text-align: right;
  direction: rtl;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_input {
  border-bottom: transparent solid 2px;
  background: #fff;
  /* height: 156px; */
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_input .Gallery_title:hover {
  background: #f1f1f14f;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_input .desc {
  display: block;
  height: 145px;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_input .desc .counterC {
  color: cadetblue;
  background-color: #d0cdcd6b;
  font-size: 12px;
  width: 25px;
  text-align: center;
  padding: 5px;
  margin: 10px;
  border-radius: 4px;
  position: relative;
  top: -53px;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Button .Roq {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Button .Roq .truncate {
  display: inline-block;
  max-width: 10ch;
  /* يعرض فقط أول 10 حروف */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  border: transparent;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Button .btn {
  width: 100% !important;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Button .info {
  font-size: small;
  color: cadetblue;
  text-align: center;
  line-height: 1.7;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Button .btn-submit {
  width: 100% !important;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Button .control {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Button .control .btn {
  width: 100% !important;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Button .control .btn-plus {
  width: 20% !important;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Button #ActionButon_ {
  color: #368299;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Button #ActionButon_ svg {
  color: transparent;
}
.wrapper-container .MainChatComponent .NewBlock .Cont_cat_Button #ActionButon_:hover svg {
  color: #368299;
}
.wrapper-container .MainChatComponent .MainCatW {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom {
  width: 90% !important;
  border-bottom: 2px dashed #eee;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom h2 {
  color: darkturquoise;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop {
  width: 90% !important;
  height: 250px;
  display: flex;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .Form {
  border-right: #03a9f4 1px dashed;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .Form .actionType {
  display: flex;
  align-items: center;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .Form .actionType .custom-radio {
  display: inline-block;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .Form .btn-success {
  margin: 5px;
  color: #fffbfb;
  font-size: 21px;
  border-radius: 4px;
  background: #03a9f4b5;
  border: 1px solid #90caf9;
  box-shadow: 0 2px 2px 0 #e3f2fd, 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 #b3e5fc;
  transition: 0.2s ease-in;
  font-family: monospace;
  padding: 10px;
  cursor: pointer;
  height: fit-content;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .Form .btn-success:hover {
  background: #59d38b;
  border: 1px solid #f1f8e9;
  box-shadow: 0 2px 2px 0 #f1f8e9, 0 3px 1px -2px #dcedc8, 0 1px 5px 0 #d8f7b5b0;
  border-radius: 8px;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .Form .info {
  font-size: small;
  text-align: right;
  line-height: 1.5;
  color: cadetblue;
  padding: 10px;
  direction: rtl;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .Form input {
  border: 1px solid #e1f5fe;
  border-radius: 6px;
  height: 33px;
  margin: 5px;
  outline: 0;
  padding-left: 10px;
  width: -webkit-fill-available;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom {
  width: 100%;
  overflow: scroll;
  max-height: 700px;
  overflow-x: hidden;
  padding: 10px;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .Button_:hover {
  border-bottom: 1px dashed #a5dcf58a;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .Button_ {
  height: 30px;
  border-bottom: 1px dashed #b5cad48a;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .Button_ .QuestValue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  width: 85%;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .Button_ .QuestValue .icon {
  color: #03a9f4;
  font-size: 14px;
  margin: 5px;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .Button_:hover {
  border-bottom: 2px dashed #03a9f4;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .Button_:hover .privPath {
  color: #ffffff;
  font-size: 11px;
  padding-left: 10px;
  float: left;
  margin: 5px;
  background-color: #03a9f4;
  padding-right: 10px;
  border-radius: 0px 5px 5px 0px;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .Button_:hover .clearItem {
  color: #e00040;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .Button_:hover .DLMsg_Selected {
  background-image: url("./../../../assets/images/check.png");
  border: #31d6f9 1px dashed;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .Active {
  border-bottom: 2px dashed #03a9f4;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .ANSWER:hover {
  border-bottom: 2px dashed #4de161;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .ANSWER:hover .privPath {
  background-color: #4de161 !important;
  color: #ffffff !important;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .AnswerActive {
  border-bottom: 2px dashed #4de161;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .ANSWER .ABtnActive {
  background-color: #4de161 !important;
  color: #ffffff !important;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .priv {
  height: 30px;
  border-bottom: 1px dashed #b5cad48a;
  padding: 2px;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .pActive {
  background-color: #03a9f4 !important;
  color: #ffffff !important;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .priv:hover {
  border-bottom: 2px dashed #b5cad48a;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .priv:hover .privPath {
  color: #ffffff;
  font-size: 11px;
  padding-left: 10px;
  float: left;
  margin: 5px;
  background-color: #03a9f4;
  padding-right: 10px;
  border-radius: 0px 5px 5px 0px;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .privPath {
  padding: 4px;
  color: #7d878c;
  font-size: 11px;
  padding-left: 10px;
  float: left;
  margin: 5px;
  background-color: honeydew;
  padding-right: 10px;
  border-radius: 0px 5px 5px 0px;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .QuestOption {
  display: flex;
  justify-content: space-between;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .QuestOption .clearItem {
  color: #f1eaee;
  font-size: 14px;
  justify-content: flex-end;
  margin: 0 10px;
  width: 20px;
  cursor: pointer;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .QuestOption .DLMsg_Selected {
  float: right;
  border-radius: 4px;
  border: dashed 1px #b3dce680;
  width: 16px;
  height: 16px;
  background-size: 66%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-right: 10px;
}
.wrapper-container .MainChatComponent .MainCatW .FormBottom .FormTop .List-bottom .QuestOption .Selected {
  background-image: url("./../../../assets/images/check.png");
  border: #31d6f9 1px dashed;
}
.wrapper-container .MainChatComponent .MainCatW .Cont_cat {
  border: #9dc1c542 dashed 2px;
  padding: 0px;
  width: 25% !important;
  background-color: #fafafa;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 25px;
}
.wrapper-container .MainChatComponent .MainCatW .Cont_cat .form .Header_Block .info {
  font-size: small;
  color: cadetblue;
  text-align: center;
  line-height: 1.7;
}
.wrapper-container .MainChatComponent .MainCatW .Cont_cat .Cont_cat_Cover {
  height: 190px;
  width: 100%;
  background-size: cover;
  border-radius: 24px 24px 0px 0px;
  position: relative;
  color: #ced0d08a;
  font-size: 45px;
  cursor: pointer;
  border-bottom: 2px solid #ffffff;
}
.wrapper-container .MainChatComponent .MainCatW .Cont_cat .Cont_cat_Button {
  display: flex;
  flex-direction: column;
}
.wrapper-container .MainChatComponent .MainCatW .Cont_cat .Cont_cat_Button .btn {
  width: 100% !important;
}
.wrapper-container .MainChatComponent .Cont_cat {
  border: #9dc1c542 dashed 2px;
  padding: 0px;
  width: 100% !important;
  background-color: #fafafa;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 25px;
}
.wrapper-container .MainChatComponent .Cont_cat .Cont_cat_Cover {
  background-image: url("./../../../assets/images/no-product.png");
  height: 330px;
  width: 100%;
  background-size: cover;
  border-radius: 24px 24px 0px 0px;
  position: relative;
  color: #ced0d08a;
  font-size: 45px;
  cursor: pointer;
  border-bottom: 2px solid #ffffff;
}
.wrapper-container .MainChatComponent .Cont_cat .Cont_cat_Cover:hover {
  border-bottom: dashed 2px #42afaf;
}
.wrapper-container .MainChatComponent .Cont_cat h1 {
  padding: 5px 0px 0px 0px;
  margin: 2px;
  font-size: 24px;
  text-align: center;
  font-family: "Amiri", serif;
  color: #0b0b0e;
}
.wrapper-container .MainChatComponent .Cont_cat .Cont_cat_desc {
  text-align: center;
  font-size: small;
  color: #757879;
  padding: 0px 5px 0px 5px;
}
.wrapper-container .MainChatComponent .Cont_cat .Cont_cat_Button {
  margin: 15px;
}
.wrapper-container .MainChatComponent .Cont_cat .Cont_cat_Button .action-btn {
  display: flex;
  align-items: center;
}
.wrapper-container .MainChatComponent .Cont_cat .Cont_cat_Button .action-btn .btn {
  margin: 0 5px 5px 5px;
}
.wrapper-container .MainChatComponent .Cont_cat .Cont_cat_Button .btn {
  background: #ffffff;
  height: 43px;
  width: 32%;
  margin-bottom: 5px;
  margin-left: 5px;
  cursor: pointer;
  direction: rtl;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.wrapper-container .MainChatComponent .Cont_cat .Cont_cat_Button .btn:hover {
  border: #9dc1c542 dashed 1px;
  background-color: #e9e9e9;
}
.wrapper-container .MainChatComponent .Cont_cat:hover {
  border: dashed 2px #73cad8;
}
.wrapper-container .MainChatComponent .isActiveEditable {
  border: dashed 2px #7de603;
}
.wrapper-container .MainChatComponent .isActiveDelete {
  border: dashed 2px #f5241494;
}
@media screen and (max-width: 780px) {
  .wrapper-container .Block_nav_ .p-title,
.wrapper-container .Block_nav_ .SearchCat,
.wrapper-container .Block_nav_ #MainCat {
    width: 98%;
  }
}
@media screen and (max-width: 870px) {
  .wrapper-container .Block_nav_ .p-title,
.wrapper-container .Block_nav_ .SearchCat,
.wrapper-container .Block_nav_ #MainCat {
    width: 98%;
  }
}

@media screen and (max-width: 430px) {
  .wrapper-container {
    height: unset !important;
  }
  .wrapper-container .Block_nav_ {
    /* padding: 25px 0 5px 25px; */
    display: flex;
    justify-content: center;
  }
  .wrapper-container .Block_nav_ .row {
    margin: 0px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .wrapper-container .Block_nav_ #MainCat {
    display: flex;
    width: 97%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .wrapper-container .mainCat {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* margin: 25px; */
    padding: 15px;
    align-items: center;
  }
  .wrapper-container .mainCat .NewBlock {
    width: 100% !important;
    /* margin-right: 5px; */
    display: flex;
    align-items: flex-end;
  }
  .wrapper-container .mainCat .NewBlock .Cont_cat {
    width: 88% !important;
  }
  .wrapper-container .mainCat .NewBlock .Cont_cat .Cont_cat_Button .btn {
    background: #ffffff;
    height: 35px;
    /* width: 32%; */
    margin-bottom: 5px;
    margin-left: 5px;
    cursor: pointer;
    direction: rtl;
    /* display: inline-block; */
    /* font-weight: 400; */
    text-align: center;
    white-space: nowrap;
    /* vertical-align: middle; */
    /* -webkit-user-select: none; */
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 11px;
    line-height: 1.5;
  }
  .wrapper-container .mainCat .MainCatW {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .wrapper-container .mainCat .MainCatW .FormBottom {
    width: 90% !important;
    height: unset !important;
    border-bottom: 2px dashed #eee;
    margin-bottom: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  .wrapper-container .mainCat .MainCatW .FormBottom .Form {
    width: unset !important;
    border-right: unset !important;
  }
  .wrapper-container .mainCat .MainCatW .FormBottom .List-bottom .Button_ .QuestValue {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    width: 85%;
  }
  .wrapper-container .mainCat .MainCatW .Cont_cat {
    border: #9dc1c542 dashed 2px;
    padding: 0px;
    width: 25%;
    background-color: #fafafa;
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 25px;
  }
}