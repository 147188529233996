.ReactModal__Content .productModal {
  width: 750px !important;
}
.ReactModal__Content .modal-content {
  display: inline-flex;
  justify-content: space-between;
  color: #52a6cd;
  padding: 20px;
  border-radius: 8px;
}
.ReactModal__Content .modal-content .productImgModal {
  width: 400px;
  height: 400px;
  background-position: center;
  border-radius: 8px;
  background-size: cover;
}
.ReactModal__Content .modal-content .productImgModal .optionModal {
  display: flex;
  height: inherit;
  width: inherit;
  justify-content: space-between;
  margin: 10px;
  flex-direction: row-reverse;
  align-items: center;
}
.ReactModal__Content .modal-content .productImgModal .optionModal .optionItem {
  width: 35%;
  height: inherit;
  margin: 10px;
}
.ReactModal__Content .modal-content .productImgModal .optionModal .optionItem .MultipleIMG_bar {
  height: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
  align-content: center;
}
.ReactModal__Content .modal-content .productImgModal .optionModal .optionItem .MultipleIMG_bar .Item {
  width: 50px;
  height: 55px;
  margin: 1px;
  background-color: #fbfefec4;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 7.5;
  border: 3px solid #ffffff;
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  cursor: pointer;
}
.ReactModal__Content .modal-content .productImgModal .optionModal .optionItem .MultipleIMG_bar .Item .RemoveImg {
  font-size: 11px;
  color: transparent;
  margin-right: 3px;
}
.ReactModal__Content .modal-content .productImgModal .optionModal .optionItem .MultipleIMG_bar .Item .RemoveImg:hover {
  color: #e53b3b;
  font-size: 15px;
}
.ReactModal__Content .modal-content .productImgModal .optionModal .optionItem .MultipleIMG_bar .Item:hover {
  opacity: 9;
  border: 3px solid;
  border-color: #274978;
}
.ReactModal__Content .modal-content .productImgModal .optionModal .optionItem .MultipleIMG_bar .Item:hover .RemoveImg {
  color: #e53b3b;
}
.ReactModal__Content .modal-content .productImgModal .optionModal .optionItem .MultipleIMG_bar .checked {
  opacity: 9;
  border: 3px solid;
  border-color: #274978;
}
.ReactModal__Content .modal-content .productImgModal .optionModal .optionAction {
  width: 50%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: stretch;
}
.ReactModal__Content .modal-content .productImgModal .optionModal .optionAction .buyAction {
  height: 50px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin-left: 20px;
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 18px;
}
.ReactModal__Content .modal-content .productImgModal .optionModal .optionAction .buyAction .ADtoCrt {
  cursor: pointer;
  font-size: x-large;
  color: transparent;
}
.ReactModal__Content .modal-content .productImgModal:hover .optionModal .optionAction .buyAction {
  background-color: #fbfbfb82;
}
.ReactModal__Content .modal-content .productImgModal:hover .optionModal .optionAction .buyAction .ADtoCrt {
  color: #1bbfb7cc;
}
.ReactModal__Content .modal-content .descInfo {
  display: flex;
  width: 45%;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-start;
  align-items: flex-end;
  background: url("../../../images/AD.png") no-repeat;
  background-position: bottom center;
  background-size: 60%;
}
.ReactModal__Content .modal-content .descInfo .pric_sec {
  display: flex;
  direction: rtl;
  align-items: center;
  justify-content: space-between;
}
.ReactModal__Content .modal-content .descInfo .pric_sec .price {
  color: #2cbb60;
  direction: rtl;
}
.ReactModal__Content .modal-content .descInfo h2 {
  font-family: "Tajawal";
  line-height: 1.7;
}
.ReactModal__Content .modal-content .descInfo span {
  color: #222121b5;
  direction: rtl;
  padding: 10px;
}
.ReactModal__Content .modal-content .descInfo .price {
  color: #33a95e;
}