.Clint .clintList {
  height: 385px;
  background: #fff;
  margin: 10px;
  display: flex;
  flex-direction: column;
  border: 1px #eee solid;
  border-radius: 0px 0px 11px 11px;
  padding-left: 10px;
  width: 300px;
  z-index: 100;
  position: absolute;
  margin-left: -125px;
}
.Clint .clintList .nav_clint {
  margin: 8px;
}
.Clint .clintList .nav_clint h2 {
  float: left;
  font-size: 19px;
  color: #505e78;
  margin: 5px;
}
.Clint .clintList .nav_clint .SyncClintList {
  float: right;
  margin: 5px;
  margin-right: 20px;
  cursor: pointer;
}
.Clint .clintList .nav_clint .Searches .ClientSearches {
  border-radius: 8px;
  /* border: #fafafa 1px solid; */
  color: #74a9a4;
  height: 30px;
  font-size: small;
  text-align: center;
  direction: rtl;
  width: 95%;
  background: no-repeat;
  background-position-x: right;
  background-position-y: 8px;
  border: 1px solid #eeeeeed9;
}
.Clint .clintList .itemList {
  height: 250px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.Clint .clintList .itemList .Clint-container {
  height: 59px;
  width: 97%;
  border-bottom: #f3f1f1 1px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
}
.Clint .clintList .itemList .Clint-container .userImg {
  margin: 2px;
  background-image: url("../../../images/DefaultCover.png");
  height: 35px;
  width: 35px;
  background-color: rgba(202, 211, 216, 0.3882352941);
  float: left;
  background-size: cover;
  opacity: 0.7;
  border-radius: 50%;
}
.Clint .clintList .itemList .Clint-container .userInfo {
  width: 80%;
  text-align: left;
  font-size: 12px;
  color: #434549;
  line-height: 1.3;
  margin-left: 5px;
}
.Clint .clintList .itemList .Clint-container .userInfo .ordersCount {
  color: forestgreen;
  background: cornsilk;
  font-size: 13px;
  padding: 2px;
}
.Clint .clintList .itemList .Selected {
  border-bottom: 2px dashed #b5e0ea;
  background-color: #ffeb6dab;
  box-shadow: -5px 5px 3px 2px #f5f4f4;
  border-left: 3px solid #00add8;
}
.Clint .clintList .itemList .Clint-container:hover {
  border-bottom: 2px dashed #b5e0ea;
  background-color: #ffeb6dab;
  box-shadow: -5px 5px 3px 2px #f5f4f4;
  border-left: 3px solid #00add8;
  cursor: pointer;
}