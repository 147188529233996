.NavControls {
  display: flex;
  background: #ffffff none repeat scroll 0 0;
  padding: 8px;
  border: 0 solid #e7e7e7;
  border-radius: 5px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  color: #6abb7d;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: 8px auto 8px auto;
}
.NavControls .Nav-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* arrows */
}
.NavControls .Nav-options .dateCalender {
  width: 100px;
}
.NavControls .Nav-options .SearchClintInvoices {
  background: url("./../../../../../assets/images/sh-robot.gif") no-repeat 10px;
  border: dashed 2px #dae2e4;
  height: 25px;
  padding: 14px;
  color: #647073;
  font-size: 13px;
  border-radius: 19px;
  direction: rtl;
  text-align: center;
  width: 19%;
  outline-style: none;
  background-size: 21px;
}
.NavControls .Nav-options .btn-success {
  background: #26dad2;
  border: 1px solid #26dad2;
  -webkit-box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.NavControls .Nav-options .AtoCalc {
  border: 1px dashed #eeeeee;
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 7px;
  cursor: pointer;
  margin: auto;
  text-align: center;
  margin-left: 4px;
  font-size: 13px;
}
.NavControls .Nav-options .AtoCalc:hover {
  border: 1px dashed #048ee4;
}
.NavControls .Nav-options input {
  margin: 5px;
  width: 58px;
  height: 30px;
  border: 1px #eee solid;
  border-radius: 5px;
  text-align: center;
  color: #6abb7d;
}
.NavControls .Nav-options input:focus {
  outline: none;
}
.NavControls .Nav-options button {
  margin: 3px;
  width: 30px;
  height: 30px;
  border: 1px #eee solid;
  border-radius: 5px;
  text-align: center;
  color: #fafafa;
}
.NavControls .Nav-options .btn-succesds:hover {
  background: #26dad2;
  -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
  box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
  border: 1px solid #26dad2;
}
.NavControls .Nav-options .btn-red {
  background: #fb3a3a;
  border: 1px solid #fb3a3a;
  color: #ffffff;
}
.NavControls .Nav-options .btn-success:hover {
  opacity: 0.7;
  border: 1px solid #fb3a3a;
  background: #fb3a3a;
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}
.NavControls .Nav-options .btn-red:active {
  background: #e6294b;
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}
.NavControls .Nav-options select {
  /* styling */
  background-color: white;
  border: thin solid #ebebf1;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.3em;
  padding: 0.5em 3.5em 0.5em 1em;
  /* reset */
  font-size: smaller;
  margin: 1px 0px 0px 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.NavControls .Nav-options .btnOpenOrder {
  width: 94%;
}
.NavControls .Nav-options select.round {
  background-image: linear-gradient(45deg, transparent 50%, #6abb7d 50%), linear-gradient(135deg, #6abb7d 50%, transparent 50%), radial-gradient(#d4f3db 70%, transparent 72%);
  background-position: calc(100% - 14px) calc(1em + 0px), calc(100% - 9px) calc(1em + 0px), calc(100% - 0.4em) 0.4em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  font-family: tahoma;
  color: #506666;
}
.NavControls .Nav-options select.round:focus {
  background-image: linear-gradient(45deg, #6abb7d 50%, transparent 50%), linear-gradient(135deg, transparent 50%, #6abb7d 50%), radial-gradient(#d4f3db 70%, transparent 72%);
  background-position: calc(100% - 12px) 1em, calc(100% - 17px) 1em, calc(100% - 0.5em) 0.4em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: #e0e2e0;
  outline: 0;
}
.NavControls .Nav-options select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}
.NavControls .Nav-options select.minimal:focus {
  background-image: linear-gradient(45deg, green 50%, transparent 50%), linear-gradient(135deg, transparent 50%, green 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}
.NavControls .Nav-options select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}