body {
  background: #f8f8f8 !important;
}

.main {
  background: #f8f8f8;
  padding: 105px 0;
  padding-top: 105px;
  max-height: 100%;
}
.main .container {
  width: 900px;
  background: #fff;
  margin: 0 auto;
  box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -o-border-radius: 20px;
  -ms-border-radius: 20px;
}
.main .container .login-content {
  padding-top: 67px;
  padding-bottom: 87px;
  display: flex;
  justify-content: center;
}
.main .container .login-content .login-image {
  background: url("../../../assets/images/signinImage.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  width: 300px !important;
  height: 300px !important;
  margin: 0 !important;
  padding: 0 !important;
}
.main .container .login-content .login-form {
  margin-right: 90px;
  margin-left: 80px;
  width: 30%;
}
.main .container .login-content .login-form h2 {
  /* line-height: 1.66; */
  margin: 10px;
  padding: 0;
  font-weight: bold;
  color: #2093e7;
  /* font-family: math; */
  font-size: x-large;
  text-align: center;
}
.main .container .login-content .login-form .register-form {
  width: 100%;
}
.main .container .login-content .login-form .register-form .form-group {
  position: relative;
  margin-bottom: 25px;
  overflow: hidden;
}
.main .container .login-content .login-form .register-form .form-group label {
  position: absolute;
  left: 5px;
  top: 7px;
  color: cadetblue;
}
.main .container .login-content .login-form .register-form .form-group input {
  border-radius: 5px;
  outline-style: none;
  text-align: left;
  direction: ltr;
  padding: 10px;
  resize: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  padding-left: 25px;
}
.main .container .login-content .login-form .register-form .form-group .input-message {
  margin-top: 15px;
}
.main .container .login-content .login-form .register-form .form-group .input-message span {
  color: #ed7f48;
}
.main .container .login-content .login-form .register-form .row .form-submit:hover {
  background: #6dabe4;
}
.main .container .login-content .login-form .register-form .row .form-submit {
  display: inline-block;
  background: #6dabe4;
  color: #fff;
  border-bottom: none;
  width: 90%;
  padding: 15px 39px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  margin-top: 25px;
  margin-left: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.main .container .login-content .login-form .register-form .row .btn2 {
  background: #6abb7d !important;
}

@media screen and (max-device-width: 430px) {
  body {
    background: #ffffff !important;
  }

  .main {
    padding: unset !important;
    background-color: #ffffff;
  }
  .main .sign-in {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main .sign-in .container {
    all: unset !important;
    width: 100% !important;
  }
  .main .sign-in .container .login-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: unset !important;
  }
  .main .sign-in .container .login-content .signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .main .sign-in .container .login-content .signup-form .form-title {
    display: none !important;
  }
  .main .sign-in .container .login-content .signup-form .form-title h2 {
    all: unset !important;
  }
  .main .sign-in .container .login-content .signup-form .register-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main .sign-in .container .login-content .signup-form .register-form .Row-x {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 98%;
  }
  .main .sign-in .container .login-content .signup-form .register-form .Row-x input {
    border: 1px solid #e1f5fe;
    outline: 0;
    height: 35px;
    margin: 5px;
    border-radius: 6px;
    padding-left: 10px;
    width: 85%;
  }
  .main .sign-in .container .login-content .signup-form .register-form .Row-x input[type=button],
.main .sign-in .container .login-content .signup-form .register-form .Row-x input[type=submit] {
    height: 45px !important;
  }
  .main .sign-in .container .login-content .signup-form .register-form .rowFixed {
    width: 85%;
    display: flex;
    flex-direction: row;
    margin: 10px 0px 10px 0px;
  }
  .main .sign-in .container .login-content .signup-form .register-form .Cover {
    width: 100%;
    height: 195px;
    background-size: 100%;
    /* background-position-y: 364px; */
    opacity: unset;
    border-radius: 0px 0px 27px 27px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .main .sign-in .container .login-content .signup-form .register-form .Cover .media2 {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 5px #fdf8f8 solid;
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .main .sign-in .container .login-content .login-image {
    background: url("../../../assets/images/signinImage.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    width: 300px !important;
    height: 300px !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 20px !important;
  }
  .main .sign-in .container .login-content .login-form {
    all: unset !important;
  }
  .main .sign-in .container .login-content .login-form .register-form .form-group {
    position: relative;
    margin-bottom: 5px;
    /* overflow: hidden; */
  }
  .main .sign-in .container .login-content .login-form .register-form .row {
    all: unset !important;
  }
}