.container-post {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0px 5px;
  background: #ffffff none repeat scroll 0 0;
  padding: 20px;
  border: 0px #ffffff;
  border-right: 4px solid #ffffff !important;
  flex-wrap: wrap;
  justify-content: center;
}
.container-post .productSize {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.container-post .productSize .S_NUM {
  cursor: pointer;
  padding: 3px;
  border: 1px dashed #b1d5c6;
  border-radius: 4px;
  color: cadetblue;
  margin: 3px;
}
.container-post .productSize .S_NUM:hover {
  background: #d4f3db;
  color: #046372b3;
  border: 1px solid #a9d9b4cf;
}
.container-post .productSize .Size-checked {
  background: #d4f3db;
  color: #046372b3;
  border: 1px solid #a9d9b4cf;
}
.container-post .ActionPost {
  width: 17%;
  margin: 0px 5px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.container-post .ActionPost .user_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-post .ActionPost .user_ .UserActionButton {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-size: cover;
  background-repeat: no-repeat;
}
.container-post .ActionPost .user_ .uN_ {
  font-size: 20px;
  font-family: "Tajawal";
  padding: 10px;
  color: dimgrey;
  text-align: center;
  cursor: pointer;
}
.container-post .ActionPost .user_ .uN_:hover {
  text-decoration: underline;
}
.container-post .ActionPost .Quantity {
  font-size: 27px;
  margin: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container-post .ActionPost .ActivQty {
  color: #ff5722cc;
}
.container-post .ActionPost .PostActionLikesButton {
  font-size: 27px;
  color: #7d8584;
  margin: 15px;
  text-align: center;
  width: 70px;
  font-family: Tajawal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-post .ActionPost .PostActionLikesButton .islike {
  color: #f73636db;
}
.container-post .ActionPost .Like {
  cursor: pointer;
}
.container-post .ActionPost .Like:hover {
  color: #f73636db;
}
.container-post .content {
  width: 60%;
  padding: 10px;
  font-family: Tajawal;
  text-align: start;
  direction: rtl;
}
.container-post .content span {
  font-family: Arial;
  line-height: 17.9999px;
  color: #4e4a4a;
  white-space: pre-wrap;
}
.container-post .content h3 {
  text-shadow: -4px 2px 6px #b5aeae;
  margin: 5px;
  font-family: "Tajawal";
  font-size: xx-large;
  text-align: center;
}
.container-post .imgPost {
  width: 80%;
  height: 370px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  opacity: 0.8;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.container-post .imgPost .buyAction {
  height: 65px;
  background-color: #fbfbfb82;
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkslateblue;
  margin: 20px;
  border-radius: 8px;
  width: fit-content;
  padding: 0px 20px;
}
.container-post .imgPost .buyAction .ADtoCrt {
  cursor: pointer;
  font-size: x-large;
  color: #f3f5f7;
}
.container-post .imgPost:hover {
  opacity: 9;
  height: 570px;
}
.container-post .imgPost:hover .ADtoCrt {
  font-size: xx-large;
  color: #ff5722cc;
}

.container-post:hover {
  border-right: 4px solid blueviolet !important;
  margin: 5px;
}