.container_last_products {
  width: 75%;
  margin: 15px auto 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-flow: wrap;
}
.container_last_products .Item {
  width: 5.5%;
}
.container_last_products .Item .product_m {
  border: #ffffff solid 3px;
  margin: 5px;
  border-radius: 8px;
  box-shadow: -2px 2px #ecf0f3;
  width: 66px;
  height: 66px;
}
.container_last_products .Item .product_m ._P_ {
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  border-radius: 6px;
  display: flex;
}
.container_last_products .Item .product_m ._P_ ._code_ {
  margin: 72px 0px 10px 1px;
  padding: 1px 6px 1px 6px;
  background-color: #00adff9e;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  color: #ffffff;
  height: fit-content;
  max-width: 100%;
  overflow: hidden;
}
.container_last_products .Item .product_m ._P_ ._code_ span {
  color: #ffffff;
  font-size: 12px;
}
.container_last_products .product_m:hover {
  border: #ffb22be8 solid 3px;
  border-radius: 8px;
}

@media screen and (max-width: 1200px) {
  .container_last_products {
    width: 95%;
    justify-content: center;
  }
  .container_last_products .Item {
    width: 11%;
    height: 100px;
  }
}
@media screen and (max-width: 481px) {
  .container_last_products {
    justify-content: center;
    width: 95%;
  }
  .container_last_products .Item {
    width: 21.5%;
  }
  .container_last_products .Item:nth-child(3n+1) {
    display: none;
  }
}
@media screen and (max-width: 681px) {
  .container_last_products {
    justify-content: center;
    padding-left: 10px;
    width: 95%;
    align-items: center;
  }
  .container_last_products .Item {
    width: 21.5%;
  }
  .container_last_products .Item:nth-child(3n+1) {
    display: none;
  }
}