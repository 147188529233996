.ReactModal__Content {
  width: fit-content !important;
}
.ReactModal__Content .clintModel {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.ReactModal__Content .modal-content {
  width: 100%;
}
.ReactModal__Content .modal-content h2 {
  text-align: center;
}
.ReactModal__Content .modal-content form .list {
  width: 75%;
  height: 35px;
  border: solid 1px #eee;
  background: transparent;
  border-radius: 8px;
  margin: 8px;
  outline: none;
}
.ReactModal__Content .modal-content form input {
  border: 1px solid #e1f5fe;
  outline: 0;
  height: 29px;
  margin: 5px;
  border-radius: 6px;
  padding-left: 10px;
}
.ReactModal__Content .modal-content form .inputError {
  border: dashed #ffcf6d 1px !important;
}
.ReactModal__Content .modal-content form .inputSuccess {
  border: dashed #0ec97b 1px !important;
}
.ReactModal__Content .modal-content form .sButon {
  display: flex;
}
.ReactModal__Content .modal-content form .btn-success {
  margin: 5px;
  color: #fffbfb;
  font-size: 21px;
  width: 100%;
  border-radius: 4px;
  background: #03a9f4b5;
  border: 1px solid #90caf9;
  box-shadow: 0 2px 2px 0 #e3f2fd, 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 #b3e5fc;
  transition: 0.2s ease-in;
  font-family: monospace;
  padding: 13px;
  cursor: pointer;
  height: fit-content;
}
.ReactModal__Content .modal-content form .btn-success:hover {
  background: #59d38b;
  border: 1px solid #f1f8e9;
  box-shadow: 0 2px 2px 0 #f1f8e9, 0 3px 1px -2px #dcedc8, 0 1px 5px 0 #d8f7b5b0;
  border-radius: 8px;
}