.FormUpdate {
  margin: 10px 5px 10px 5px;
}
.FormUpdate .FU_tools {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.FormUpdate .FU_tools h2 {
  color: cadetblue;
  font-size: 20px;
  margin: 5px;
}
.FormUpdate .FU_tools .Edit {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.FormUpdate .FU_tools .Edit .Check-inOrder {
  width: 30px;
  border-radius: 4px;
  background-size: 68%;
  background-repeat: no-repeat;
  background-position: center;
}
.FormUpdate .FU_tools .Edit .Checked-true {
  background-image: url("./../../../../assets/images/check.png");
}
.FormUpdate .FU_tools .Edit .Check-inOrder:hover {
  background-image: url("./../../../../assets/images/check.png");
}
.FormUpdate .FU_tools .Edit .Button-custom {
  border: 1px dashed #70a6e2ba;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  color: #6abb7d;
  margin: 3px;
  padding: 7px;
  height: 30px;
}
.FormUpdate .FU_tools .Edit .Active {
  color: coral;
}
.FormUpdate button {
  width: -webkit-fill-available;
  background: #26dad2;
  border: 1px solid #26dad2;
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  transition: 0.2s ease-in;
  margin: 3px;
  height: 53px;
  border-radius: 5px;
  text-align: center;
  color: #fafafa;
}
.FormUpdate .btn-success:hover {
  opacity: 0.7;
  border: 1px solid #fb3a3a;
  background: #fb3a3a;
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}
.FormUpdate .style16 {
  border: none;
  border-top: 1px dashed rgba(160, 210, 252, 0.38);
}
.FormUpdate .cart-items {
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.FormUpdate .cart-items .INorder {
  border-left: 3px solid #77c389;
  border-radius: 0px 5px 5px 0px !important;
}
.FormUpdate .cart-items .loading {
  padding-top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.FormUpdate .cart-items .item {
  border-bottom: 1px dashed #d8dde5ab;
  height: 56px;
  width: 98%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
}
.FormUpdate .cart-items .item .item-description {
  width: 85%;
}
.FormUpdate .cart-items .item .item-description .title {
  margin: 8px;
  color: #6a726f;
  font-size: 14px;
}
.FormUpdate .cart-items .item .item-description div {
  font-size: smaller;
  margin: 5px;
}
.FormUpdate .cart-items .item .item-description div span {
  font-size: 12px;
}
.FormUpdate .cart-items .item .item-description div .span-price {
  color: #2d9714;
}
.FormUpdate .cart-items .item .item-description div .span-Q {
  color: #8468dd;
}
.FormUpdate .cart-items .item .productImg {
  height: 50px;
  width: 50px;
  background-position: center;
  background-size: 98%;
  background-repeat: no-repeat;
  border-radius: 8px;
  margin: 5px 5px 0px 5px;
}
.FormUpdate .cart-items .item .clearItem {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  color: #f1eaee;
  justify-content: flex-end;
}
.FormUpdate .cart-items .item:hover {
  background-color: #ebeced;
}
.FormUpdate .cart-items .item-selected {
  background-color: #d4f3db;
}
.FormUpdate .cart-items .item:hover .clearItem {
  color: #db2f85;
  cursor: pointer;
}