.filterContainer .pointCamp {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.filterContainer .pointCamp .list {
  width: fit-content;
  height: 35px;
  border: solid 1px #eee;
  background: transparent;
  border-radius: 8px;
  margin: 8px;
  outline: none;
}
.filterContainer .pointCamp .btn-success:hover {
  opacity: 0.7;
  border: 1px solid #fb3a3a;
  background: #fb3a3a;
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}
.filterContainer .pointCamp button {
  width: 75px;
  background: #26dad2;
  border: 1px solid #26dad2;
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  transition: 0.2s ease-in;
  margin: 3px;
  border-radius: 5px;
  text-align: center;
  color: #fafafa;
  padding: 6px;
}
.filterContainer .pointCamp input[type=text],
.filterContainer .pointCamp input[type=time] {
  border: 1px solid rgba(238, 238, 238, 0.7215686275);
  border-radius: 8px;
  font-size: 21px;
  padding: 5px;
  outline: none;
  color: rgba(100, 95, 106, 0.92);
  text-align: right;
  direction: rtl;
  text-shadow: -4px 2px 6px #948c8c;
  margin: 5px;
  font-family: Tajawal;
  width: 98%;
}
.filterContainer .pointCamp .checkAction {
  color: transparent;
  padding: 5px;
  border-radius: 7px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  font-size: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #dfe5e7c4 dashed;
  margin-right: 10px;
}
.filterContainer .pointCamp .checkAction:hover {
  color: #ffb22b;
  border: 1px #30befbe8 dashed;
}
.filterContainer .pointCamp .Checked-true {
  color: #ffb22b;
  border: 1px #30befbe8 dashed;
}
.filterContainer .style16 {
  width: 100%;
  border: none;
  border-bottom: 1px dashed rgba(160, 210, 252, 0.38);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 5px;
  flex-direction: row-reverse;
  color: cadetblue;
}
.filterContainer .style16 .icon {
  width: 30px;
  height: 30px;
  background: url("../../../images/bulb_off_64.png") no-repeat;
  background-position: center;
  transform: scaleX(-1);
  background-size: 80%;
}
.filterContainer .style16 .btnBar {
  height: 35px;
  width: 35%;
  display: flex;
  align-items: center;
}
.filterContainer .style16 .btnBar .Button-custom {
  border: 1px dashed #eeeeee;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  color: #1dc9e0;
  margin: 3px;
  padding: 5px 7px;
  width: 35px;
}
.filterContainer .style16 .btnBar .Button-custom:hover {
  border: 1px dashed #048ee4;
  color: #f00d99;
  cursor: pointer;
}
.filterContainer .style16 h2 {
  font-size: 20px;
  margin: 5px;
  text-shadow: -4px 2px 6px #777777;
  font-family: "Tajawal" !important;
  color: #2188b7 !important;
  direction: rtl;
}
.filterContainer .SuppliersList {
  height: 250px;
  overflow: scroll;
}
.filterContainer .SuppliersList .Supplier {
  display: flex;
  margin: 5px 0px 5px 0px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  text-align: right;
  padding: 5px;
}
.filterContainer .SuppliersList .Supplier .op-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.filterContainer .SuppliersList .Supplier .op-btn .pushAction {
  color: #03a9f4e6;
  padding: 5px;
  border-radius: 10px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  font-size: 17px;
  height: 31px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #03a9f4c4 dashed;
}
.filterContainer .SuppliersList .Supplier .op-btn .sh {
  color: darkseagreen;
}
.filterContainer .SuppliersList .Supplier .pushAction:hover {
  color: #ffb22b;
  border: 1px #ffb22b dashed;
}
.filterContainer .SuppliersList .Supplier .checkAction {
  color: transparent;
  padding: 5px;
  border-radius: 7px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  font-size: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #dfe5e7c4 dashed;
  margin-right: 10px;
}
.filterContainer .SuppliersList .Supplier .checkAction:hover {
  color: #ffb22b;
  border: 1px #30befbe8 dashed;
}
.filterContainer .SuppliersList .Supplier .Checked-true {
  color: #ffb22b;
  border: 1px #30befbe8 dashed;
}
.filterContainer .SuppliersList .Supplier .details {
  background: #eeeeee7d;
  width: -webkit-fill-available;
  margin: 0px 10px 0px 0px;
  border-radius: 0px 0px 10px 10px;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 11px;
  padding: 10px;
  font-family: "Amiri", serif;
  direction: rtl;
  display: flex;
  align-items: center;
}
.filterContainer .SuppliersList .Supplier .details strong {
  color: #03a9f4;
}
.filterContainer .SuppliersList .Supplier .details .title {
  color: #266c8d;
  font-family: "Amiri", serif;
  padding: 0 5px;
}
.filterContainer .SuppliersList .Supplier .FixFlex {
  align-items: flex-start !important;
  flex-direction: column !important;
}