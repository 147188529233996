.OrderInfo {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.OrderInfo .fa-angle-left {
  color: #33d0c9;
}
.OrderInfo .Total {
  width: 30%;
}
.OrderInfo .Total h1 {
  color: #939b9d;
  text-decoration: line-through;
}
.OrderInfo .Total h2 {
  color: turquoise;
}
.OrderInfo .clintName {
  text-align: right;
  width: 60%;
  direction: rtl;
}
.OrderInfo .clintName h2 {
  color: darkmagenta;
  padding-bottom: 0px;
  margin: 5px;
  font-weight: 400;
  font-family: inherit;
  line-height: 20px;
  font-size: 18px;
}
.OrderInfo .clintName span {
  font-size: small;
  color: #2f3636;
  font-family: "Open Sans", sans-serif;
  color: #898c8e;
  font-size: 13px;
}

.orderOption {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: aliceblue;
  border-radius: 7px;
  margin: 8px 0 8px;
}
.orderOption .inputError {
  border: dashed #ffcf6d 1px !important;
}
.orderOption .inputSuccess {
  border: dashed #0ec97b 1px !important;
}
.orderOption input,
.orderOption select {
  border: 1px solid #eeebeb;
  height: 28px;
  margin: 7px;
  width: 16%;
  color: #292e2e;
  outline: none;
  border-radius: 4px;
  background: transparent;
}
.orderOption input[type=text] {
  text-align: center;
  color: blueviolet;
  background: #fff;
}
.orderOption button[type=submit] {
  background-color: #26dad2;
  color: #fff;
  height: 33px;
  width: 80px;
}
.orderOption input[type=button]:hover {
  background-color: #26dad2;
  cursor: pointer;
}
.orderOption select {
  width: 25%;
}