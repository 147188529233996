@charset "UTF-8";
.B1:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 12px;
  content: "\f302";
}

.B2:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 9px;
  content: "\f2bb";
}

.B3:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 9px;
  content: "\f03a";
}

.B4:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 9px;
  content: "\f037";
}

.B5:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 9px;
  content: "\f130";
}

.B6:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 9px;
  content: "\f03d";
}

.B7:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 9px;
  content: "\f0c6";
}

.B8:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 9px;
  content: "\f041";
}

.B9:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 9px;
  content: "\f095";
}

.B10:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 9px;
  content: "\f2b6";
}

.B11:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 9px;
  content: "\f119";
}

.B12:before {
  position: absolute;
  font-family: "FontAwesome";
  left: 9px;
  content: "\f24d";
}

.LeftSidebar {
  float: left;
  background-color: #eeeeee8a;
  position: fixed;
  border-radius: 0px 10px 18px 0px;
  padding: 5px;
  z-index: 99;
  left: 0px;
  top: 107px;
  padding-top: 35px;
}
.LeftSidebar .dark {
  background-color: #080707d1;
}
.LeftSidebar ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.LeftSidebar ul li {
  width: 35px;
  height: 35px;
  text-align: center;
  border: dashed 1px transparent;
  margin: 5px;
  list-style: none;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 0.6s ease-in-out;
}
.LeftSidebar ul li.activeItem {
  border: dashed 1px #3bacf9;
  color: #3bacf9;
  background-color: #fffffff2;
}
.LeftSidebar ul li:hover {
  cursor: pointer;
  border: dashed 1px #3bacf9;
  color: #3bacf9;
}
.LeftSidebar ul li i {
  font-size: 45px;
}
.LeftSidebar .popup-menu {
  position: absolute;
  background-color: #fffffff2;
  /* نفس خلفية الشريط الجانبي */
  border-radius: 0px 10px 18px 0px;
  /* نفس الحواف للشريط الجانبي */
  padding: 10px;
  /* نفس الحشوة */
  z-index: 1000;
  margin-top: 5px;
  left: 55px;
  /* تباعد القائمة عن الشريط الجانبي */
  width: max-content;
  /* زيادة عرض القائمة المنبثقة */
  border-left: #3bacf9 solid 2px !important;
}
.LeftSidebar .popup-menu::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  border-width: 8px 8px 8px 0;
  border-style: solid;
  border-color: transparent #3bacf9 transparent transparent;
}
.LeftSidebar .popup-menu.visible {
  opacity: 1;
  /* إظهار القائمة */
  transform: translateY(0);
  /* إعادة القائمة لمكانها الطبيعي */
  border: #eeeeeeb0 solid 2px;
}
.LeftSidebar .popup-menu.activeItem {
  border: dashed 1px #3bacf9;
  transition: all 0.6s ease-in-out;
}
.LeftSidebar .popup-menu .popup-item {
  display: flex;
  font-size: smaller;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  justify-content: flex-start;
  border-bottom: 1px dashed transparent;
}
.LeftSidebar .popup-menu .popup-item .fico {
  font-size: 15px;
}
.LeftSidebar .popup-menu .popup-item i,
.LeftSidebar .popup-menu .popup-item span {
  transition: color 0.3s ease;
  color: #000;
  padding-left: 5px;
}
.LeftSidebar .popup-menu .popup-item:hover {
  border-bottom: 1px dashed #b6d3e1d4;
}
.LeftSidebar .popup-menu .popup-item:hover i,
.LeftSidebar .popup-menu .popup-item:hover span {
  color: #3bacf9;
}

/* @media screen and (max-device-width: 430px) {
  .LeftSidebar {
    background-color: #eee;
    position: fixed;
    z-index: 123;
    width: 100%;
    bottom: 30px;
    height: 50px;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      width: -webkit-fill-available;
    }
  }
}
 */