.wrapper-container {
  background: #fff;
}
.wrapper-container hr {
  display: block !important;
  border: none;
  border-top: 1px dashed rgba(160, 210, 252, 0.38);
  border-bottom: none;
}
.wrapper-container .row {
  margin: 0px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.wrapper-container .TRansition {
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.wrapper-container h1 {
  color: #29b3c3;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
}
.wrapper-container .Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper-container .Container hr {
  display: block !important;
  border: none;
  border-top: 1px dashed rgba(160, 210, 252, 0.38);
  border-bottom: none;
  width: 75%;
  margin: 8px;
}
.wrapper-container .Container .QuestForm {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 75%;
}
.wrapper-container .Container .QuestForm form {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.wrapper-container .Container .QuestForm form textarea {
  border-radius: 5px;
  width: 98%;
  height: 45px;
  min-height: 45px;
  overflow: hidden;
  resize: none;
  outline-style: none;
  direction: rtl;
  font-size: 1.3em;
  text-align: right;
  color: #242825d1;
  text-shadow: -4px 2px 6px #b5aeae;
  border: 2px dashed rgba(20, 193, 227, 0.72);
  font-family: Tajawal !important;
  padding: 10px;
  margin: 12px 0px 2px 0px;
}
.wrapper-container .Container .QuestForm form textarea::selection {
  background-color: #3cce60;
  /* Change this to your desired background color */
  color: #fff;
  /* Change this to your desired text color */
}
.wrapper-container .Container .QuestForm form .Answer {
  width: 50%;
}
.wrapper-container .Container .QuestForm form .Answer textarea {
  max-height: 130px;
  resize: vertical;
}
.wrapper-container .Container .QuestForm form .Answer .AnswerButton {
  color: #3f90b2;
  padding-top: 5px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.wrapper-container .Container .QuestForm form .Answer .AnswerButton button {
  background-color: transparent !important;
  border: solid 1px #eee;
  padding: 3px 10px 3px 10px;
  border-radius: 7px !important;
  color: deepskyblue;
}
.wrapper-container .Container .QuestForm form .Answer .AnswerButton button:hover {
  color: #4eb0dd;
  border: dashed 1px #4eb0dd;
}
.wrapper-container .Container .QuestForm form .Answer .AnswerButton .Button {
  cursor: pointer;
}
.wrapper-container .Container .QuestForm form .Questions {
  width: 50%;
}
.wrapper-container .Container .ASQuest_container {
  display: flex;
  justify-content: space-around;
  width: 75%;
  align-items: flex-start;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest,
.wrapper-container .Container .ASQuest_container .ASQ_answer {
  width: 48%;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .toolbar,
.wrapper-container .Container .ASQuest_container .ASQ_answer .toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #eee;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .toolbar .SectionTitle,
.wrapper-container .Container .ASQuest_container .ASQ_answer .toolbar .SectionTitle {
  width: 50%;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .toolbar .SectionTitle h4,
.wrapper-container .Container .ASQuest_container .ASQ_answer .toolbar .SectionTitle h4 {
  color: cadetblue;
  font-size: 23px;
  margin: 5px;
  padding-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  width: 100%;
  direction: rtl;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .toolbar .optionButton,
.wrapper-container .Container .ASQuest_container .ASQ_answer .toolbar .optionButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .toolbar .optionButton .Button-custom,
.wrapper-container .Container .ASQuest_container .ASQ_answer .toolbar .optionButton .Button-custom {
  border: 1px dashed #eeeeee;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  color: cadetblue;
  margin: 3px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .toolbar .optionButton .Button-custom:hover,
.wrapper-container .Container .ASQuest_container .ASQ_answer .toolbar .optionButton .Button-custom:hover {
  border: 1px dashed #32e3e9;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .toolbar .optionButton .Check-select,
.wrapper-container .Container .ASQuest_container .ASQ_answer .toolbar .optionButton .Check-select {
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: center;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .toolbar .optionButton .Check-select:hover,
.wrapper-container .Container .ASQuest_container .ASQ_answer .toolbar .optionButton .Check-select:hover {
  background-image: url("./../../../assets/images/check.png");
  border: #31d6f9 1px dashed !important;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .toolbar .optionButton .Selected,
.wrapper-container .Container .ASQuest_container .ASQ_answer .toolbar .optionButton .Selected {
  background-image: url("./../../../assets/images/check.png");
  border: #31d6f9 1px dashed !important;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer {
  width: 100%;
  overflow: scroll;
  max-height: 700px;
  overflow-x: hidden;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .Button_:hover,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .Button_:hover {
  border-bottom: 1px dashed #a5dcf58a;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .Button_,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .Button_ {
  height: 30px;
  border-bottom: 1px dashed #b5cad48a;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .Button_ .QuestValue,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .Button_ .QuestValue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  width: 85%;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .Button_ .QuestValue .icon,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .Button_ .QuestValue .icon {
  color: #03a9f4;
  font-size: 14px;
  margin: 5px;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .Button_:hover,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .Button_:hover {
  border-bottom: 2px dashed #03a9f4;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .Button_:hover .privPath,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .Button_:hover .privPath {
  color: #ffffff;
  font-size: 11px;
  padding-left: 10px;
  float: left;
  margin: 5px;
  background-color: #03a9f4;
  padding-right: 10px;
  border-radius: 0px 5px 5px 0px;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .Button_:hover .clearItem,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .Button_:hover .clearItem {
  color: #e00040;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .Button_:hover .DLMsg_Selected,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .Button_:hover .DLMsg_Selected {
  background-image: url("./../../../assets/images/check.png");
  border: #31d6f9 1px dashed;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .Active,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .Active {
  border-bottom: 2px dashed #03a9f4;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .ANSWER:hover,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .ANSWER:hover {
  border-bottom: 2px dashed #4de161;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .ANSWER:hover .privPath,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .ANSWER:hover .privPath {
  background-color: #4de161 !important;
  color: #ffffff !important;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .AnswerActive,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .AnswerActive {
  border-bottom: 2px dashed #4de161;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .ANSWER .ABtnActive,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .ANSWER .ABtnActive {
  background-color: #4de161 !important;
  color: #ffffff !important;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .priv,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .priv {
  height: 30px;
  border-bottom: 1px dashed #b5cad48a;
  padding: 2px;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .pActive,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .pActive {
  background-color: #03a9f4 !important;
  color: #ffffff !important;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .priv:hover,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .priv:hover {
  border-bottom: 2px dashed #b5cad48a;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .priv:hover .privPath,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .priv:hover .privPath {
  color: #ffffff;
  font-size: 11px;
  padding-left: 10px;
  float: left;
  margin: 5px;
  background-color: #03a9f4;
  padding-right: 10px;
  border-radius: 0px 5px 5px 0px;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .privPath,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .privPath {
  padding: 4px;
  color: #7d878c;
  font-size: 11px;
  padding-left: 10px;
  float: left;
  margin: 5px;
  background-color: honeydew;
  padding-right: 10px;
  border-radius: 0px 5px 5px 0px;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .QuestOption,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .QuestOption {
  display: flex;
  justify-content: space-between;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .QuestOption .clearItem,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .QuestOption .clearItem {
  color: #f1eaee;
  font-size: 14px;
  justify-content: flex-end;
  margin: 0 10px;
  width: 20px;
  cursor: pointer;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .QuestOption .DLMsg_Selected,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .QuestOption .DLMsg_Selected {
  float: right;
  border-radius: 4px;
  border: dashed 1px #b3dce680;
  width: 16px;
  height: 16px;
  background-size: 66%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-right: 10px;
}
.wrapper-container .Container .ASQuest_container .ASQ_Quest .answer .QuestOption .Selected,
.wrapper-container .Container .ASQuest_container .ASQ_answer .answer .QuestOption .Selected {
  background-image: url("./../../../assets/images/check.png");
  border: #31d6f9 1px dashed;
}