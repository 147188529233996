header {
  grid-area: header;
  color: #21aced;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .main-header {
  /*     display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 75%; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  background-color: #ffffff;
  padding: 0px 31px;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
}
header .main-header .sysOptions {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
header .main-header .sysOptions .singout {
  cursor: pointer;
}
header .main-header .HeadToolBar {
  width: 45%;
  width: 100%;
  display: flex;
}
header .main-header .HeadToolBar ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
}
header .main-header .HeadToolBar ul .TRansition {
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
header .main-header .HeadToolBar ul li {
  color: #67757c;
  width: 35px;
  height: 35px;
  text-align: center;
  border: dashed 1px transparent;
  margin: 5px;
  list-style: none;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
}
header .main-header .HeadToolBar ul li:hover {
  cursor: pointer;
  border: dashed 1px #3bacf9;
  color: #3bacf9;
}
header .main-header .userInfo {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: small;
}
header .main-header .userInfo .btn-NewPge {
  color: #fff;
  background: #3bacf9;
  padding: 7px;
  border-radius: 5px;
  font-style: italic;
  cursor: pointer;
}
header .main-header .userInfo .ActivePage {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: small;
  padding-right: 25px;
}
header .main-header .userInfo .myPages {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 280px;
  height: 300px;
  background-color: #feffff;
  position: fixed;
  top: 47px;
  margin-left: -55px;
  border-radius: 0 0px 6px 6px;
  border: 1px #28252524 solid;
  border-top: 0px;
  display: none;
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
header .main-header .userInfo .myPages .btn-NewPge {
  color: #fff;
  background: #3bacf9;
  padding: 7px 25px;
  border-radius: 5px;
  font-style: italic;
  cursor: pointer;
  height: 35px;
  width: 55%;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 5px;
  justify-content: space-around;
}
header .main-header .userInfo .myPages .item {
  border-bottom: 1px #eeeeee80 solid;
  display: flex;
  align-items: center;
  color: #333232;
  cursor: pointer;
  width: 95%;
  font-size: small;
  margin-top: 5px;
  padding: 5px;
  flex-direction: row-reverse;
  justify-content: flex-start;
  border-radius: 8px;
}
header .main-header .userInfo .myPages .item div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column-reverse;
}
header .main-header .userInfo .myPages .item .userIMG {
  background-color: #f5f5f5;
  background-size: cover;
  border-radius: 50%;
  height: 34px;
  margin: 5px;
  width: 34px;
  background-position: center;
  background-repeat: no-repeat;
}
header .main-header .userInfo .myPages .active {
  background-color: #ecf0f380;
  color: #333232;
}
header .main-header .userInfo .myPages .item:hover {
  background-color: #ecf0f380;
  color: #333232;
}
header .main-header .userInfo .info {
  display: flex;
  flex-direction: column;
}
header .main-header .userInfo .info .GroupName {
  color: #999ea1;
}
header .main-header .userInfo .userIMG {
  background-color: #f5f5f5;
  background-size: cover;
  border-radius: 50%;
  height: 34px;
  margin: 5px;
  width: 34px;
  background-position: center;
  background-repeat: no-repeat;
}
header .main-header .logo {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
header .main-header .logo h1 {
  cursor: pointer;
}

@media screen and (max-device-width: 430px) {
  header {
    grid-area: header;
    background-color: #ffffff;
    color: #21aced;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: -webkit-fill-available;
    z-index: 110;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  header .main-header {
    display: flex;
    width: 90%;
    justify-content: center;
  }
  header .main-header .logo {
    width: unset !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  header .main-header .userInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: small;
  }
  header .main-header .userInfo .ActivePage {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: small;
    padding: unset !important;
  }
  header .main-header .userInfo .userIMG {
    background-color: #f5f5f5;
    background-size: cover;
    border-radius: 50%;
    height: 34px;
    margin: 5px;
    width: 34px;
    background-position: center;
    background-repeat: no-repeat;
  }
  header .main-header .userInfo .info {
    display: flex;
    flex-direction: column;
    width: max-content;
    padding: 0px 10px;
  }
  header .main-header .HeadToolBar {
    display: flex;
    justify-content: center;
  }
  header .main-header .HeadToolBar {
    display: flex;
    justify-content: center;
  }
  header .main-header .HeadToolBar ul {
    display: flex;
    width: 75%;
  }
}