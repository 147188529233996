.HCover {
  height: 350px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  width: 102.2%;
  margin-left: -45px;
  margin-top: -5px;
}
.HCover .HCover__Content {
  display: flex;
  align-items: center;
  height: inherit;
  justify-content: space-evenly;
}
.HCover .HCover__Content .counter {
  width: 45%;
  display: flex;
  align-items: center;
  padding: 25px;
  border-radius: 15px;
}
.HCover .HCover__Content .counter .SubscribeInfo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}
.HCover .HCover__Content .counter .SubscribeInfo .SubscribeCount h1 {
  font-size: 88px;
  color: #feffff;
  font-family: "Tajawal";
  font-weight: 600;
  /* text-shadow: -3px 0px 3px #9e9e9e; */
  height: 80px;
}
.HCover .HCover__Content .counter .SubscribeInfo .SubscribeCount h1 span {
  font-size: 20px;
  font-family: monospace;
}
.HCover .HCover__Content .counter .SubscribeInfo .pageName h2 {
  font-family: Aref Ruqaa;
  font-size: 2em;
  color: #ffffff;
}
.HCover .HCover__Content .upload {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.HCover .HCover__Content .CoverUploaded {
  justify-content: flex-end;
}
.HCover .HCover__Content .CoverUploaded .sB {
  border: 1px dashed #8ae5f1;
  height: 35px;
  border-radius: 8px;
  background: rgba(195, 252, 251, 0.2);
  width: -moz-fit-content;
  padding-right: 20px;
  padding-left: 20px;
  margin: 5px;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
}
.HCover .HCover__Content .CoverUploaded .sB:hover {
  background: rgba(18, 147, 144, 0.76);
  color: white;
  font-weight: bold;
}
.HCover .HCover__Content .CoverUploaded .btn {
  height: 80px;
  width: 80px;
  opacity: 0.4;
  cursor: pointer;
  font-size: xxx-large;
  color: #eaeeef54;
  background-color: #b8bec147;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  background-image: url("../../../../../../src/assets/images/camera.png");
}
.HCover .HCover__Content .CoverUploaded .btn input[type=file] {
  left: 0;
  top: 0;
  opacity: 0;
  height: inherit;
  width: inherit;
}
.HCover .HCover__Content .CoverUploaded .btn:hover {
  color: #ffffff96;
  opacity: 0.9;
}
.HCover .HCover__Content .custom {
  background: #7bb7d13b;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.HCover .HCover__Content .custom .subscribe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.HCover .HCover__Content .custom .subscribe .sB {
  border: 1px dashed #8ae5f1;
  height: 35px;
  border-radius: 8px;
  background: rgba(195, 252, 251, 0.2);
  width: -moz-fit-content;
  width: 40%;
  padding-right: 20px;
  padding-left: 20px;
  margin: 5px;
  color: #071211;
  cursor: pointer;
  font-weight: bold;
}
.HCover .HCover__Content .custom .subscribe .sB:hover {
  background: rgba(18, 147, 144, 0.76);
  color: white;
  font-weight: bold;
}
.HCover .HCover__Content .custom .subscribe .IsSubscribe {
  background: rgba(18, 147, 144, 0.76);
  color: white;
  font-weight: bold;
}
.HCover .HCover__Content .custom .subscribe .fllowers {
  height: 45px;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.HCover .HCover__Content .custom .subscribe .fllowers .fllower {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-image: url("../../../../images/DefaultCover.png");
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid #fdffff;
  margin-left: -17px;
}
.HCover .HCover__Content .custom .subscribe .fllower:hover {
  border: 4px solid #307e7ead;
  height: 50px;
  width: 50px;
  cursor: pointer;
  position: relative;
}