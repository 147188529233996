@charset "UTF-8";
.wrapper-invoice {
  display: flex;
  justify-content: center;
  margin: 25px 25px 0px 25px;
}
.wrapper-invoice .UnLockOrder {
  cursor: pointer;
}
.wrapper-invoice .Inv-wrapper-right-side {
  display: flex;
  flex-direction: column;
  padding: 7px;
  width: 24%;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-2 {
  height: 100px;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis {
  background-color: #ffffff;
  border-radius: 25px;
  border: 1px solid #e7e7e7ab;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  /* height: 165px; */
  margin: 15px;
  padding: 25px 10px 25px 10px;
  min-height: fit-content;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis hr {
  border: 1px solid #e7e7e7ab;
  margin: 5px;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .TopClint {
  display: flex;
  height: 30px;
  margin: 2px;
  flex-direction: row-reverse;
  align-items: center;
  border-bottom: 1px solid #f1f4f5;
  justify-content: flex-start;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .TopClint span {
  margin-left: 10px;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .TopClint .City {
  color: #5aaff7;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .TopClint .City::after {
  font-size: 11px;
  content: " ⭐️ ";
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .TopClint .QV {
  background-color: #5aaff7;
  margin: 5px;
  padding: 2px 5px;
  color: #fff;
  font-style: revert;
  border-radius: 3px;
  font-family: cursive;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .TopClint .userImg {
  margin: 6px;
  background-image: url("../../images/DefaultCover.png");
  height: 22px;
  width: 22px;
  background-color: #cad3d863;
  float: left;
  background-size: cover;
  opacity: 0.7;
  border-radius: 50%;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .TopClint:hover {
  border-bottom: 1px dashed #c1d3e3;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis h1 {
  font-size: xxx-large;
  color: #0cbb8b;
  text-align: center;
  font-family: cursive;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis h3 {
  color: currentcolor;
  text-align: center;
  font-family: monospace;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .mQ {
  font-size: xx-large;
  color: #a0a7a1;
  text-decoration: line-through #77a79a dashed;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .wallet {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .wallet div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28%;
  height: 30px;
  color: darkcyan;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .wallet div img {
  height: 25px;
  margin: 5px;
}
.wrapper-invoice .Inv-wrapper-right-side .Content-Analysis .wallet div span {
  font-size: 12px;
}
.wrapper-invoice .list-orders-wrapper {
  width: 68%;
}
.wrapper-invoice .list-orders-wrapper a {
  color: slategray;
}
.wrapper-invoice .list-orders-wrapper .Wallet_img {
  width: 25px;
  height: 20px;
  border-radius: 3px;
}
@media screen and (max-device-width: 430px) {
  .NavControls {
    display: flex;
    background: #ffffff none repeat scroll 0 0;
    padding: 8px;
    border: 0 solid #e7e7e7;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    color: #6abb7d;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 8px auto 8px auto;
    position: relative;
    z-index: 100 !important;
  }
  .NavControls .Nav-options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .NavControls .Nav-options .SearchClintInvoices {
    width: 75% !important;
  }
  .NavControls .Nav-options .btn-success {
    background: #26dad2;
    border: 1px solid #26dad2;
    -webkit-box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
    box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
  }
  .NavControls .Nav-options .btn-success:hover {
    background: #26dad2;
    -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    border: 1px solid #26dad2;
  }
  .NavControls .Nav-options .AtoCalc {
    border: 1px dashed #eeeeee;
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 7px;
    cursor: pointer;
    margin: auto;
    text-align: center;
    margin-left: 4px;
    font-size: 13px;
  }
  .NavControls .Nav-options .AtoCalc:hover {
    border: 1px dashed #048ee4;
  }
  .NavControls .Nav-options input {
    margin: 5px;
    width: 58px;
    height: 30px;
    border: 1px #eee solid;
    border-radius: 5px;
    text-align: center;
    color: #6abb7d;
  }
  .NavControls .Nav-options input:focus {
    outline: none;
  }
  .NavControls .Nav-options button {
    margin: 3px;
    width: 30px;
    height: 30px;
    border: 1px #eee solid;
    border-radius: 5px;
    text-align: center;
    color: #fafafa;
  }

  .wrapper-invoice {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    margin: unset;
    padding-left: 55px;
  }
  .wrapper-invoice .Inv-wrapper-right-side {
    width: 100%;
  }
  .wrapper-invoice .list-orders-wrapper {
    display: none !important;
  }
}