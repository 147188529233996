.cart-wrapper {
  display: flex;
  flex-direction: column;
}
.cart-wrapper button {
  width: -webkit-fill-available;
  background: #26dad2;
  border: 1px solid #26dad2;
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  transition: 0.2s ease-in;
  margin: 3px;
  height: 53px;
  border-radius: 5px;
  text-align: center;
  color: #fafafa;
}
.cart-wrapper .btn-success:hover {
  opacity: 0.7;
  border: 1px solid #fb3a3a;
  background: #fb3a3a;
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}
.cart-wrapper .cart-title {
  color: #14c6d3;
  display: inline-flex;
  align-items: center;
}
.cart-wrapper .cart-title .cart-shopping {
  width: 15%;
}
.cart-wrapper .cart-title .cart-shopping i {
  margin: 0px 10px;
}
.cart-wrapper .cart-title .options-container {
  display: flex;
  flex-direction: row;
}
.cart-wrapper .cart-title .options-container .Check-inOrder {
  width: 30px;
  border-radius: 4px;
  background-size: 68%;
  background-repeat: no-repeat;
  background-position: center;
}
.cart-wrapper .cart-title .options-container .Checked-true {
  background-image: url("./../../../../assets/images/check.png");
}
.cart-wrapper .cart-title .options-container .Check-inOrder:hover {
  background-image: url("./../../../../assets/images/check.png");
}
.cart-wrapper .cart-title .options-container .Button-custom {
  border: 1px dashed #eeeeee;
  border-radius: 10px;
  cursor: pointer;
  margin: auto;
  text-align: center;
  color: #6abb7d;
  margin: 3px;
  padding: 5px 7px;
}
.cart-wrapper .cart-title .options-container .edit {
  background-color: cornsilk;
}
.cart-wrapper .cart-title .options-container .Button-custom:hover {
  border: 1px dashed #048ee4;
}
.cart-wrapper .cart-items {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.cart-wrapper .cart-items .INorder {
  border-left: 3px solid #77c389;
  border-radius: 0px 5px 5px 0px !important;
}
.cart-wrapper .cart-items .loading {
  padding-top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.cart-wrapper .cart-items .item {
  border-bottom: 1px dashed #d8dde5ab;
  height: 56px;
  width: 98%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
}
.cart-wrapper .cart-items .item .item-description {
  width: 85%;
}
.cart-wrapper .cart-items .item .item-description .title {
  margin: 5px;
  color: #6a726f;
  font-size: 14px;
}
.cart-wrapper .cart-items .item .item-description div {
  font-size: smaller;
  margin: 5px;
}
.cart-wrapper .cart-items .item .item-description div span {
  font-size: 12px;
}
.cart-wrapper .cart-items .item .item-description div .span-price {
  color: #2d9714;
}
.cart-wrapper .cart-items .item .item-description div .span-Q {
  color: #db2f85;
}
.cart-wrapper .cart-items .item .productImg {
  height: 42px;
  width: 50px;
  background-position: center;
  background-size: 98%;
  background-repeat: no-repeat;
  border-radius: 8px;
  margin: 5px 5px 0px 5px;
}
.cart-wrapper .cart-items .item .clearItem {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  color: #f1eaee;
  justify-content: flex-end;
}
.cart-wrapper .cart-items .item:hover {
  background-color: #ebeced;
}
.cart-wrapper .cart-items .item-selected {
  background-color: #d4f3db;
}
.cart-wrapper .cart-items .item:hover .clearItem {
  color: #db2f85;
  cursor: pointer;
}