.OBjCmp {
  height: 35px;
  display: flex;
  color: #1f3c3b;
  background-color: #8ee2fd;
  border-radius: 4px;
  justify-content: space-around;
  text-shadow: -4px 2px 6px #948c8c;
  font-family: "Tajawal" !important;
  align-items: center;
}
.OBjCmp .title {
  width: 75%;
  text-align: right;
}
.OBjCmp .Check-select {
  padding: 5px;
  border-radius: 7px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border: 1px #dfe5e7c4 dashed;
  margin-right: 10px;
  background-color: #fff;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -29px;
}
.OBjCmp .Check-select:hover {
  background-image: url("./../../../../assets/images/check.png");
  border: #31d6f9 1px dashed !important;
}
.OBjCmp .Selected {
  background-image: url("./../../../../assets/images/check.png");
  border: #31d6f9 1px dashed !important;
}

.SuppliersList {
  overflow: scroll;
  height: 240px;
  background-color: #fff;
}
.SuppliersList .Supplier {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  text-align: right;
  padding: 3px;
}
.SuppliersList .Supplier .op-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.SuppliersList .Supplier .op-btn .pushAction {
  color: #03a9f4e6;
  padding: 5px;
  border-radius: 10px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  font-size: 17px;
  height: 31px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #03a9f4c4 dashed;
}
.SuppliersList .Supplier .op-btn .sh {
  color: darkseagreen;
}
.SuppliersList .Supplier .pushAction:hover {
  color: #ffb22b;
  border: 1px #ffb22b dashed;
}
.SuppliersList .Supplier .checkAction {
  color: transparent;
  padding: 5px;
  border-radius: 7px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  font-size: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #dfe5e7c4 dashed;
  margin-right: 10px;
}
.SuppliersList .Supplier .checkAction:hover {
  color: #ffb22b;
  border: 1px #30befbe8 dashed;
}
.SuppliersList .Supplier .Checked-true {
  color: #ffb22b;
  border: 1px #30befbe8 dashed;
}
.SuppliersList .Supplier .details {
  background: #eeeeee7d;
  width: -webkit-fill-available;
  margin: 0px 10px 0px 0px;
  border-radius: 0px 0px 10px 10px;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 11px;
  padding: 4px 10px;
  font-family: "Amiri", serif;
  display: flex;
  align-items: center;
  direction: rtl;
  justify-content: space-between;
  flex-direction: row;
}
.SuppliersList .Supplier .details strong {
  color: #03a9f4;
}
.SuppliersList .Supplier .details .title {
  color: #266c8d;
  font-family: "Amiri", serif;
  padding: 0 5px;
}
.SuppliersList .Supplier .SyncProducts_btn {
  color: transparent;
}
.SuppliersList .Supplier:hover .SyncProducts_btn {
  color: #ffb22b;
  cursor: pointer;
}