.row {
  margin-top: 30px;
  width: 98%;
  display: flex;
  justify-content: space-evenly;
}
.row .card > div ~ div {
  margin-top: 17px;
}
.row .editTool {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.row .editTool .EditPencil {
  cursor: pointer;
  color: #90caf9;
}
.row .editTool .fa-trash {
  color: coral;
  cursor: pointer;
}
.row .left-saidbar {
  display: flex;
  width: 25%;
  flex-direction: column;
}
.row .left-saidbar h2 {
  text-align: center;
}
.row .left-saidbar .AnalyticsCity {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
.row .left-saidbar .AnalyticsCity .city {
  color: #5d635d;
  border: 1px dashed #8ae5f1;
  border-radius: 9px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 21px;
  margin: 2px;
  cursor: pointer;
  height: 29px;
  background: rgba(195, 252, 251, 0.2);
}
.row .left-saidbar .AnalyticsCity .city span {
  color: #e4138dad;
}
.row .left-saidbar .AnalyticsCity .city:hover {
  background: rgba(187, 247, 246, 0.45);
}
.row .left-saidbar .NewSuppliers .box-body form .form-group .list {
  width: 75%;
  border: solid 1px #eee;
  background: transparent;
  border-radius: 8px;
  margin: 8px;
  outline: none;
  font-size: 16px;
  /* Adjust font size to affect option height */
  padding: 10px;
}
.row .left-saidbar .NewSuppliers .box-body form input {
  border: 1px solid #e1f5fe;
  outline: 0;
  height: 29px;
  margin: 5px;
  border-radius: 6px;
  padding-left: 10px;
}
.row .left-saidbar .NewSuppliers .box-body form .inputError {
  border: dashed #ffcf6d 1px !important;
}
.row .left-saidbar .NewSuppliers .box-body form .inputSuccess {
  border: dashed #0ec97b 1px !important;
}
.row .left-saidbar .NewSuppliers .box-body form .sButon {
  display: flex;
}
.row .left-saidbar .NewSuppliers .box-body form .btn-success {
  margin: 5px;
  color: #fffbfb;
  font-size: 21px;
  width: 100%;
  border-radius: 4px;
  background: #03a9f4b5;
  border: 1px solid #90caf9;
  box-shadow: 0 2px 2px 0 #e3f2fd, 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 #b3e5fc;
  transition: 0.2s ease-in;
  font-family: monospace;
  padding: 13px;
  cursor: pointer;
  height: fit-content;
}
.row .left-saidbar .NewSuppliers .box-body form .btn-success:hover {
  background: #59d38b;
  border: 1px solid #f1f8e9;
  box-shadow: 0 2px 2px 0 #f1f8e9, 0 3px 1px -2px #dcedc8, 0 1px 5px 0 #d8f7b5b0;
  border-radius: 8px;
}
.row .LastClint {
  width: 65%;
}
.row .LastClint .headClint {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: flex-start;
}
.row .LastClint .headClint input {
  background: url("./../../../assets/images/sh-robot.gif") no-repeat 10px;
  border: dashed 2px #dae2e4;
  height: 50px;
  padding: 16px;
  color: #647073;
  font-size: 22px;
  border-radius: 19px;
  direction: rtl;
  text-align: center;
  width: 26%;
  outline-style: none;
  background-size: 30px;
}
.row .LastClint .headClint .page-header {
  margin-bottom: unset;
  text-transform: capitalize;
}
.row .LastClint .headClint h1 {
  margin: 0px;
  padding: 0px;
}

@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .row {
    margin-top: 60px;
    width: 96%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .row .LastClint {
    width: 98%;
  }
  .row .NewClint {
    width: 98%;
  }
}
@media screen and (max-device-width: 820px) {
  .row {
    margin-top: 60px;
    width: 96%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .row .LastClint {
    width: 98%;
  }
  .row .NewClint {
    width: 98%;
  }
}