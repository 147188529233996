body {
  font-size: 16px;
}
body .Layout .main-print-invoice {
  background: white;
  font-weight: bold;
  color: #373838;
  padding-top: 10px;
  width: 100%;
  max-height: 100%;
  margin: auto;
}
body .Layout .main-print-invoice .FooterInvoice {
  margin: 10px;
  width: 95%;
}
body .Layout .main-print-invoice .FooterInvoice .priv {
  float: left;
  width: 75%;
}
body .Layout .main-print-invoice .FooterInvoice .priv h2 {
  font-size: 17px;
  text-align: right;
  direction: rtl;
}
body .Layout .main-print-invoice .FooterInvoice .priv h2 strong,
body .Layout .main-print-invoice .FooterInvoice .priv h2 div {
  color: red;
}
body .Layout .main-print-invoice .FooterInvoice .total {
  float: right;
  margin-top: 10px;
  width: 20%;
}
body .Layout .main-print-invoice .FooterInvoice .total h1 {
  color: #2372b1;
  text-align: right;
  direction: rtl;
  padding: 0px;
  margin: 3px;
}
body .Layout .main-print-invoice .FooterInvoice .total h1:first-of-type {
  color: #939b9d;
  text-decoration: line-through;
}
body .Layout .main-print-invoice .FooterInvoice .total .inv-mony {
  font-weight: unset;
  font-size: 12px;
  text-align: right;
}
body .Layout .main-print-invoice .row {
  direction: rtl;
  margin-bottom: 15px;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 0px !important;
}
body .Layout .main-print-invoice .row .col-lg-5 {
  float: left;
  width: 250px;
}
body .Layout .main-print-invoice .row .col-lg-5 .marketer_s {
  direction: rtl;
}
body .Layout .main-print-invoice .row .col-lg-7 {
  padding-right: 10px;
  float: right;
}
body .Layout .main-print-invoice img {
  width: 100% !important;
}
body .Layout .main-print-invoice .clerfx {
  clear: both;
  height: 10px;
  width: 100%;
  background: white;
}
body .Layout .main-print-invoice .TC_ {
  text-align: center;
}
body .Layout .main-print-invoice h3,
body .Layout .main-print-invoice h4 {
  text-align: center;
}
body .Layout .main-print-invoice h4 {
  font-style: italic;
  text-decoration: underline;
}
body .Layout .main-print-invoice table {
  margin: auto;
}
body .Layout .main-print-invoice table td {
  padding-left: 5px;
}
body .Layout .main-print-invoice table thead {
  background: #eee;
}
body .Layout .main-print-invoice table thead th {
  background: #eee;
  height: 35px;
}
body .Layout .main-print-invoice table tbody tr {
  height: 25px;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
body .Layout .main-print-invoice table tbody tr td:nth-child(1n+4) {
  text-align: center;
}
body .Layout .main-print-invoice table,
body .Layout .main-print-invoice th,
body .Layout .main-print-invoice td {
  border-collapse: collapse;
}
body .tbor {
  border: 1px solid #7b9aa9a8;
}
body .Signature {
  width: 685px;
  margin: auto;
  margin-top: 10px;
}
body .Signature h4 {
  text-align: left;
}

@media print {
  @page {
    size: a5;
    margin: 22px 0px 40px 0px;
  }
  @page {
    size: portrait;
  }
  body .Layout {
    background: white;
  }
}