main .wrapper .products-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 76%;
}
main .wrapper .products-wrapper .product {
  float: left;
  margin: 5px;
  border-radius: 8px;
  box-shadow: -2px 2px #ecf0f3;
  background-size: cover;
  background-position-y: 0%;
  border: 5px #cdd2cd solid;
  opacity: 0.8;
  width: 215px;
  height: 190px;
  padding: 5px;
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
main .wrapper .products-wrapper .product .editTool {
  display: flex;
  justify-content: space-around;
  height: 30px;
  font-size: 14px;
  align-items: center;
  width: 98%;
  border-radius: 0px 0px 8px 8px;
  margin-top: -5px;
  color: transparent;
  /*       .TRind {
    color: #defffe;
  } */
}
main .wrapper .products-wrapper .product .editTool div {
  margin: 5px;
}
main .wrapper .products-wrapper .product .editTool .eye_dropper:hover {
  color: #0d68f1 !important;
}
main .wrapper .products-wrapper .product .editTool .TRind:hover {
  color: #f10d7f !important;
  font-size: 30px inherit;
}
main .wrapper .products-wrapper .product .editTool .IsTRind {
  color: #f10d7f;
  font-size: 25px inherit;
}
main .wrapper .products-wrapper .product .editTool .IsTRind:hover {
  color: #defffe;
}
main .wrapper .products-wrapper .product .productAction {
  display: flex;
  align-items: center;
  width: 98%;
  justify-content: space-around;
}
main .wrapper .products-wrapper .product .productAction .buyAction {
  height: 50px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 18px;
}
main .wrapper .products-wrapper .product .productAction .buyAction .ADtoCrt {
  cursor: pointer;
  font-size: x-large;
  color: transparent;
}
main .wrapper .products-wrapper .product:hover {
  border: #ffb22be8 solid 5px;
  opacity: 0.9;
  cursor: pointer;
}
main .wrapper .products-wrapper .product:hover .editTool {
  background-color: #fbfbfb82;
}
main .wrapper .products-wrapper .product:hover .editTool div {
  color: #1bbfb7cc;
}
main .wrapper .products-wrapper .product:hover .buyAction {
  background-color: #fbfbfb82;
}
main .wrapper .products-wrapper .product:hover .buyAction .ADtoCrt {
  color: #1bbfb7cc;
}
main .wrapper .products-wrapper .Zoom {
  width: 315px !important;
  height: 290px !important;
}
main .wrapper .products-wrapper .Selected {
  border: #ffb22be8 solid 5px !important;
  opacity: 0.9 !important;
  cursor: pointer;
}
main .wrapper .products-wrapper .Hide {
  border: #2a4548 solid 5px;
  opacity: 0.3;
  cursor: pointer;
}
main .wrapper .products-wrapper ._code_ {
  border-radius: 5px;
  width: fit-content;
  color: #ffffff;
  padding: 1px 6px 1px 6px;
  background-color: #1bbfb7cc;
  font-size: 14px;
}
main .wrapper .products-wrapper ._code_ strong {
  color: #ffffff;
}

@media screen and (max-device-width: 430px) {
  main {
    margin: 0px !important;
  }
  main .fixedContainer .NavControls {
    display: flex;
    background: #ffffff none repeat scroll 0 0;
    padding: 8px;
    z-index: 100;
    border: 0 solid #e7e7e7;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    color: #6abb7d;
    width: 90%;
    justify-content: center;
    margin: 8px auto 8px auto;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    height: 202px;
  }
  main .fixedContainer .NavControls .Nav-options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  main .fixedContainer .NavControls .Nav-options .BtNum {
    display: none !important;
  }
  main .wrapper {
    display: flex;
    margin: 150px 0px 0px 45px !important;
    flex-direction: column;
    align-items: center;
  }
  main .wrapper .products-wrapper {
    width: 100%;
  }
  main .wrapper .products-wrapper .product {
    float: left;
    margin: 5px;
    border-radius: 8px;
    box-shadow: -2px 2px #ecf0f3;
    background-size: cover;
    background-position-y: 0%;
    border: 5px #cdd2cd solid;
    opacity: 0.8;
    width: 165px;
    height: 165px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
  }
  main .wrapper .wrapper-right-side {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 7px;
    max-height: 870px;
    overflow-x: hidden;
    overflow-y: scroll;
    width: fit-content;
    position: relative !important;
    margin-left: 20px !important;
    margin-top: 10px;
  }
  main .wrapper .wrapper-right-side .OrderInfo p {
    display: none !important;
  }
  main .wrapper .wrapper-right-side .orderOption form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px 0px 11px 0px;
  }
}