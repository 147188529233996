.ListComing .HeadComp {
  height: 35px;
  display: flex;
  color: #080707f0;
  background-color: #8ee2fd;
  border-radius: 4px;
  justify-content: center;
  text-shadow: -4px 2px 6px #948c8c;
  font-family: Tajawal;
  align-items: center;
}
.ListComing .HeadComp svg {
  margin-left: 10px;
}

.EditCampaign {
  display: flex;
  flex-direction: column;
  display: flex;
}
.EditCampaign .style16 {
  width: 100%;
  border: none;
  border-bottom: 1px dashed rgba(160, 210, 252, 0.38);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 5px;
  flex-direction: row-reverse;
  color: cadetblue;
}
.EditCampaign .style16 .icon {
  width: 30px;
  height: 30px;
  background: url("../../../images/bulb_off_64.png") no-repeat;
  background-position: center;
  transform: scaleX(-1);
  background-size: 80%;
}
.EditCampaign .style16 .btnBar {
  height: 35px;
  width: 35%;
  display: flex;
  align-items: center;
}
.EditCampaign .style16 .btnBar .Button-custom {
  border: 1px dashed #eeeeee;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  color: #1dc9e0;
  margin: 3px;
  padding: 5px 7px;
  width: 35px;
}
.EditCampaign .style16 .btnBar .Button-custom:hover {
  border: 1px dashed #048ee4;
  color: #f00d99;
  cursor: pointer;
}
.EditCampaign .style16 h2 {
  font-size: 20px;
  margin: 5px;
  text-shadow: -4px 2px 6px #777777;
  font-family: "Tajawal" !important;
  color: #2188b7 !important;
  direction: rtl;
}
.EditCampaign button {
  width: 20%;
  background: #26dad2;
  border: 1px solid #26dad2;
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  transition: 0.2s ease-in;
  margin: 3px;
  border-radius: 5px;
  text-align: center;
  color: #fafafa;
  padding: 2px;
}
.EditCampaign .OBjCmp {
  height: 35px;
  display: flex;
  color: #080707f0;
  background-color: #8ee2fd;
  border-radius: 4px;
  justify-content: center;
  text-shadow: -4px 2px 6px #948c8c;
  font-family: "Tajawal" !important;
  align-items: center;
}
.EditCampaign .OBjCmp svg {
  margin-left: 10px;
}
.EditCampaign .listOBJ {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.EditCampaign .listOBJ .HeadComp {
  width: 95%;
  direction: rtl;
  padding: 6px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px dashed #f7f2f2;
  align-items: center;
  color: cadetblue;
}
.EditCampaign .listOBJ .HeadComp .TitleComp {
  width: 60%;
}
.EditCampaign .listOBJ .HeadComp .op {
  display: flex;
  width: 45%;
  justify-content: space-between;
  align-items: center;
}
.EditCampaign .listOBJ .formObject {
  width: 98%;
  background: rgba(176, 191, 191, 0.12);
  direction: rtl;
  padding: 6px;
  border-bottom: 1px dashed #f7f2f2;
}
.EditCampaign .listOBJ .formObject .opComp {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  border-bottom: 1px #EEE SOLID;
  padding-bottom: 3px;
}
.EditCampaign .listOBJ .formObject .opComp .list {
  width: fit-content;
  height: 30px;
  border: solid 1px #eee;
  background: transparent;
  border-radius: 4px;
  outline: none;
}
.EditCampaign .listOBJ .formObject .opComp .btnAdd {
  width: 25px;
  height: 25px;
  border: #c1c6c9 1px dashed;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: darkgray;
  cursor: pointer;
}
.EditCampaign .listOBJ .formObject .opComp .btnAdd:hover {
  border: #048ee4 1px dashed;
  color: #048ee4;
}
.EditCampaign .listOBJ .item-obj {
  width: 95%;
  direction: rtl;
  padding: 3px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #f7f2f2;
  align-items: center;
  flex-direction: row;
}
.EditCampaign .listOBJ .item-obj .check {
  height: 20px;
  width: 20px;
  background-size: 12px !important;
  border: #b5b8b9 1px dashed;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border-radius: 3px;
}
.EditCampaign .listOBJ .item-obj .check:hover {
  border: #048ee4 1px dashed;
  background: url("../../../images/check.png");
  transition: all 0.6s ease-in-out;
}
.EditCampaign .listOBJ .item-obj .checked {
  border: #048ee4 1px dashed;
  background: url("../../../images/check.png");
  transition: all 0.6s ease-in-out;
}
.EditCampaign .listOBJ .item-obj .title {
  text-shadow: -4px 2px 6px #948c8c;
  font-family: "tahoma";
  color: #3a3a3c;
  font-size: 14px;
}
.EditCampaign .listOBJ .item-obj .op {
  display: flex;
  width: 45%;
  justify-content: space-between;
  align-items: center;
}
.EditCampaign .listOBJ .item-obj .op span {
  color: #3e403ed6;
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  font-size: smaller;
}
.EditCampaign .listOBJ .item-obj .op .showPrice {
  height: 20px;
  width: 45px;
  background-position: center !important;
  background-size: 85% !important;
  background-repeat: no-repeat !important;
}
.EditCampaign .listOBJ .item-obj .op .active {
  background: url("../../../images/SW-ON.png");
}
.EditCampaign .listOBJ .item-obj .op .UnActive {
  background: url("../../../images/SW-OFF.png");
}
.EditCampaign .pointCamp {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.EditCampaign .pointCamp .list {
  width: fit-content;
  height: 35px;
  border: solid 1px #eee;
  background: transparent;
  border-radius: 8px;
  margin: 8px;
  outline: none;
}
.EditCampaign .btn-success:hover {
  opacity: 0.7;
  border: 1px solid #fb3a3a;
  background: #fb3a3a;
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}
.EditCampaign input[type=text],
.EditCampaign input[type=time] {
  border: 1px solid rgba(238, 238, 238, 0.7215686275);
  border-radius: 8px;
  font-size: 21px;
  padding: 5px;
  outline: none;
  color: rgba(100, 95, 106, 0.92);
  text-align: right;
  direction: rtl;
  text-shadow: -4px 2px 6px #948c8c;
  margin: 5px;
  font-family: Tajawal;
  width: 98%;
}
.EditCampaign input[type=time] {
  width: 30%;
  text-align: center;
  color: #777a7d;
  font-size: medium;
  direction: ltr;
  padding-top: 10px;
}
.EditCampaign .inputError {
  border: #fb3a3a2b 1px dashed !important;
}
.EditCampaign .inputTrue {
  border: #14a76e2b 1px dashed !important;
}

.ListCampaign {
  height: 400px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
}
.ListCampaign .Item {
  border-bottom: rgba(237, 237, 237, 0.57) solid 1px;
  width: 100%;
  padding: 5px 5px 10px 0px;
  border-radius: 6px 6px 0px 0px;
}
.ListCampaign .Item span {
  font-family: Tajawal !important;
}
.ListCampaign .Item h2 {
  text-shadow: -4px 2px 6px #948c8c;
  font-size: 21px;
  margin: 3px;
  width: 100%;
  text-align: right;
  padding: 0px 50px;
  color: #3f4a3d;
  font-family: Tajawal !important;
  direction: rtl;
}
.ListCampaign .Item .dtop {
  width: 98%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.ListCampaign .Item .dtop .option {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  color: transparent;
  cursor: pointer;
}
.ListCampaign .Item .dtop .icon {
  width: 40px;
  height: 40px;
  background: url("../../../images/bulb_off_64.png") no-repeat;
  background-size: 95%;
  background-position: center;
  margin-left: 10px;
  transform: scaleX(-1);
}
.ListCampaign .Item .dtop .infoComp {
  text-align: right;
  font-size: 12px;
  color: dimgray;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  direction: rtl;
  width: 65%;
  padding: 5px;
  outline: none;
  color: rgba(100, 95, 106, 0.92);
  text-align: right;
  direction: rtl;
  text-shadow: -4px 2px 6px #948c8c;
  margin: 5px;
  font-family: Tajawal;
}
.ListCampaign .Item .dtop .infoComp .title {
  color: #1b1d1a;
}
.ListCampaign .Item .dtop .infoComp .ObjectiveCampaign {
  color: #4e9706;
  font-size: small;
}
.ListCampaign .Item .dtop .infoComp .activeItem {
  cursor: pointer;
  color: #74e206;
}
.ListCampaign .Item .dtop .infoComp .UnActiveItem {
  cursor: pointer;
  color: #fb3a3a;
}
.ListCampaign .Item:hover {
  background-color: #f6f7f7;
}
.ListCampaign .Item:hover h2,
.ListCampaign .Item:hover span,
.ListCampaign .Item:hover .option {
  color: #3f4a3d;
  direction: rtl;
  font-family: Tajawal !important;
}