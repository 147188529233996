.activeCampaign {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.activeCampaign .HeadComp {
  height: 35px;
  display: flex;
  color: #080707f0;
  background-color: #8ee2fd;
  border-radius: 4px;
  text-shadow: -4px 2px 6px #948c8c;
  font-family: Tajawal;
  align-items: center;
  width: 100%;
  border-bottom: 1px dashed rgba(160, 210, 252, 0.38);
  align-items: center;
  padding-bottom: 5px;
  justify-content: flex-end;
  padding-right: 15px;
}
.activeCampaign .HeadComp .title {
  margin: 4px;
}
.activeCampaign .BodyComp {
  display: flex;
  flex-direction: column;
  max-height: 120px;
  overflow-y: scroll;
}
.activeCampaign .BodyComp .Item:hover {
  background-color: #eeeeee75;
}
.activeCampaign .BodyComp .Item {
  direction: rtl;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  border-bottom: solid 1px #eee;
  font-family: Tajawal !important;
  text-shadow: -5px 5px 6px #929597;
  color: #2a2828;
  font-size: 12px;
}
.activeCampaign .BodyComp .Item .Check-select {
  padding: 5px;
  border-radius: 7px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border: 1px #dfe5e7c4 dashed;
  margin-right: 10px;
  background-color: #fff;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -29px;
}
.activeCampaign .BodyComp .Item .Check-select:hover {
  background-image: url("./../../../../../assets/images/check.png");
  border: #31d6f9 1px dashed !important;
}
.activeCampaign .BodyComp .Item .Selected {
  background-image: url("./../../../../../assets/images/check.png");
  border: #31d6f9 1px dashed !important;
}
.activeCampaign .BodyComp .Item .title {
  width: 85%;
  direction: rtl;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.activeCampaign .BodyComp .Item .title .State {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 10px;
  border: 1px solid #bcc2c7;
}
.activeCampaign .BodyComp .Item .title .activeItem {
  cursor: pointer;
  background-color: #abd7b3;
}
.activeCampaign .BodyComp .Item .title .UnActiveItem {
  cursor: pointer;
  background-color: #fb3a3a6b;
}
.activeCampaign .BodyComp .Item .title .icon {
  width: 30px;
  height: 30px;
  background: url("../../../../images/bulb_off_64.png") no-repeat;
  background-position: center;
  transform: scaleX(-1);
  background-size: 80%;
}