.modal-content-clint {
  background-color: white;
  direction: rtl;
  display: flex;
  justify-content: center;
  padding: 75px;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  border: double 10px rgba(196, 196, 196, 0.14);
  margin: 5%;
  margin-top: 10%;
}
.modal-content-clint h1 {
  color: #048ee4;
  margin: 2px;
}