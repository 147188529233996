footer {
  bottom: 0px;
  grid-area: footer;
  background-color: #d9d9d970;
  color: #7595a9;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  font-size: small;
  width: 100%;
  position: fixed;
  z-index: 122;
}