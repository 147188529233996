@charset "UTF-8";
.wrapper-Audience h1 {
  color: #29b3c3;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
}
.wrapper-Audience .NavAud {
  padding: 0px 0px 0px 42px;
  width: 98%;
}
.wrapper-Audience .NavAud .row {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper-Audience .NavAud .p-title {
  width: 30%;
}
.wrapper-Audience .NavAud hr .style16 {
  border: none;
  border-top: 1px dashed rgba(160, 210, 252, 0.38);
  border-bottom: none;
  margin-top: 20px;
}
.wrapper-Audience .NavAud hr .style16:after {
  content: "✂";
  display: inline-block;
  position: relative;
  top: -15px;
  left: 47px;
  padding: 0 3px;
  /*  background: #fafafa;*/
  color: #03a9f4;
  font-size: 18px;
}
.wrapper-Audience .NavAud .SearchCat {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-Audience .NavAud .SearchCat input {
  margin: 8px;
  border: dashed 1px #dae2e4;
  height: 45px;
  padding: 16px;
  color: #647073;
  font-size: 22px;
  border-radius: 19px;
  direction: rtl;
  text-align: center;
  background: url("./../../../assets/images/sh-robot.gif") no-repeat 10px;
  width: 90%;
  outline-style: none;
  background-size: 25px;
}
.wrapper-Audience .NavButton {
  display: flex;
  color: #6abb7d;
  /*         .Checked-true {
      background-image: url("./../../../assets/images/check.png");
  }

  .Check-Audience:hover {
      background-image: url("./../../../assets/images/check.png");
  } */
}
.wrapper-Audience .NavButton .AtoCalc {
  border: 1px dashed #cdc8c8ab;
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 7px;
  cursor: pointer;
  margin: auto;
  text-align: center;
  margin-left: 4px;
  font-size: 13px;
}
.wrapper-Audience .NavButton .AtoCalc:hover {
  border: 1px dashed #048ee4;
}
.wrapper-Audience .NavButton .Check-Audience {
  width: 30px;
  border-radius: 4px;
  background-size: 68%;
  background-repeat: no-repeat;
  background-position: center;
}
.wrapper-Audience .Checked-true {
  background-image: url("./../../../assets/images/check.png");
}
.wrapper-Audience .Check-Audience:hover {
  background-image: url("./../../../assets/images/check.png");
}
.wrapper-Audience .MainAudience {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0px 0px 0px 30px;
  background: #fff;
  padding-top: 20px;
  width: 97%;
  min-height: 80vh;
}
.wrapper-Audience .MainAudience .LiftBar {
  width: 20%;
  padding-right: 10px;
}
.wrapper-Audience .MainAudience .LiftBar .labels .title {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: flex-start;
}
.wrapper-Audience .MainAudience .LiftBar .labels .title h2 {
  font: 1em sans-serif;
  color: darkviolet;
  font-size: 25px;
  margin: 5px;
}
.wrapper-Audience .MainAudience .LiftBar .labels .title h2 svg {
  color: #6abb7d;
}
.wrapper-Audience .MainAudience .LiftBar .labels .list-label button {
  width: -webkit-fill-available;
  background: #26dad2;
  border: 1px solid #26dad2;
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  transition: 0.2s ease-in;
  margin: 3px;
  height: 53px;
  border-radius: 5px;
  text-align: center;
  color: #fafafa;
}
.wrapper-Audience .MainAudience .LiftBar .labels .list-label .btn-success:hover {
  opacity: 0.7;
  border: 1px solid #fb3a3a;
  background: #fb3a3a;
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}
.wrapper-Audience .MainAudience .LiftBar .labels .list-label .label-list-style:hover {
  color: #00add8;
  cursor: pointer;
  border-bottom: 1px #bee5efdb solid;
}
.wrapper-Audience .MainAudience .LiftBar .labels .list-label .label-list-style:hover .control div {
  font-size: smaller;
  color: #2e7d9c;
}
.wrapper-Audience .MainAudience .LiftBar .labels .list-label .label-list-style {
  border-bottom: dashed #eeeeee9c 1px;
  margin: 3px;
  font-size: 15px;
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
}
.wrapper-Audience .MainAudience .LiftBar .labels .list-label .label-list-style .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper-Audience .MainAudience .LiftBar .labels .list-label .label-list-style .option .Aud_labels {
  height: 15px;
  width: 15px;
  border: 1px #d7e4fb solid;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  cursor: pointer;
  float: left;
  margin: 0px 7px 0px 6px;
}
.wrapper-Audience .MainAudience .LiftBar .labels .list-label .label-list-style .option .Aud_labels:hover {
  background-image: url("./../../../assets/images/check.png");
  border: 1px rgba(181, 226, 122, 0.73) solid;
}
.wrapper-Audience .MainAudience .LiftBar .labels .list-label .label-list-style .control {
  display: flex;
  width: 12%;
  justify-content: space-between;
}
.wrapper-Audience .MainAudience .LiftBar .labels .list-label .label-list-style .control div {
  font-size: smaller;
  color: #fdfdfd;
}
.wrapper-Audience .MainAudience .LiftBar .labels .label_desc {
  padding: 4px;
  border-top: solid 1px #d0e7ec;
  margin-top: 10px;
  font-size: small;
  color: #5e919e;
  text-align: center;
  line-height: 1.5;
}
.wrapper-Audience .MainAudience .LiftBar .labels .form-label {
  display: flex;
  background: #fcfcfc;
  justify-content: center;
  align-items: center;
}
.wrapper-Audience .MainAudience .LiftBar .labels .form-label .BPicker {
  height: 40px;
  width: 30px;
  border-radius: 6px;
}
.wrapper-Audience .MainAudience .LiftBar .labels .form-label .BPicker .Color {
  position: fixed;
  display: none;
  position: inherit;
  padding: 42px;
}
.wrapper-Audience .MainAudience .LiftBar .labels .form-label .BPicker:hover .Color {
  display: block;
}
.wrapper-Audience .MainAudience .LiftBar .labels .form-label input {
  margin: 8px;
  border: dashed 1px #dae2e4;
  height: 45px;
  padding: 16px;
  color: #647073;
  font-size: 22px;
  border-radius: 6px;
  direction: rtl;
  text-align: center;
  width: 60%;
  outline-style: none;
  background-size: 25px;
}
.wrapper-Audience .MainAudience .LiftBar .labels .form-label .btn {
  background: #90ceff;
  height: 43px;
  /* width: 100%; */
  margin-bottom: 5px;
  cursor: pointer;
  direction: rtl;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.wrapper-Audience .MainAudience .LiftBar .labels .form-label .btn:hover {
  background-color: #65b6f6;
}
.wrapper-Audience .MainAudience .RightPage {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 80%;
  border-left: #ededed 1px solid;
  min-height: 75vh;
}
.wrapper-Audience .MainAudience .RightPage .more {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-Audience .MainAudience .RightPage .more .loadMore {
  /* margin: 10px; */
  width: 90%;
  border: 1px solid rgba(94, 206, 225, 0.14);
  /* margin: 15px; */
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-Audience .MainAudience .RightPage .more .loadMore:hover {
  color: #6abb7d;
}
.wrapper-Audience .MainAudience .RightPage .audience {
  height: 60px;
  width: fit-content;
  max-width: 200px;
  float: left;
  margin: 5px;
  overflow: hidden;
  border: 1px solid #e9ecefd9;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 25px 0px 0px;
  background-color: beige;
}
.wrapper-Audience .MainAudience .RightPage .audience .labelsColor {
  width: 7px;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.wrapper-Audience .MainAudience .RightPage .audience .labelsColor .lab {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  margin-top: 1px;
  background-color: #de30e1;
  opacity: 0.6;
}
.wrapper-Audience .MainAudience .RightPage .audience .labelsColor div:nth-child(3) {
  background: #2faddf;
}
.wrapper-Audience .MainAudience .RightPage .audience .audInfo {
  line-height: 1.1em;
  height: 55px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.wrapper-Audience .MainAudience .RightPage .audience .audInfo .optionAud img {
  height: 15px;
}
.wrapper-Audience .MainAudience .RightPage .audience .audInfo span {
  font-size: 13px;
  overflow: hidden;
}
.wrapper-Audience .MainAudience .RightPage .audience .Img {
  height: 45px;
  width: 45px;
  border: solid 1px #d4dfe880;
  border-radius: 50%;
  float: left;
  margin: 5px;
  background-image: url("../../images/DefaultCover.png");
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.7;
}
.wrapper-Audience .MainAudience .RightPage .audience:hover {
  color: #2f4587;
  background-color: #dee2e67a;
}
.wrapper-Audience .MainAudience .RightPage .audience:hover .Img {
  opacity: 0.9;
}
.wrapper-Audience .MainAudience .RightPage .audience:hover .labelsColor .lab {
  opacity: 0.9;
}
.wrapper-Audience .MainAudience .RightPage .Selected {
  color: #2f4587;
  background-color: #dee2e67a;
}
.wrapper-Audience .MainAudience .RightPage .Selected .Img {
  opacity: 0.9;
}
.wrapper-Audience .MainAudience .RightPage .Selected .labelsColor .lab {
  opacity: 0.9;
}